import React from 'react'

const QuoteIcon = () => {
  return (
    <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_70_800)">
        <path d="M45.2083 7.75C48.9766 7.75 52.5906 9.24695 55.2551 11.9115C57.9197 14.5761 59.4167 18.1901 59.4167 21.9583C59.4167 27.7553 56.3993 33.3612 51.8966 34.4436C50.158 34.8621 48.7759 37.0915 49.073 39.6361C49.4708 43.0409 52.5502 46.4819 58.6494 49.1944C60.0082 49.7989 59.4399 51.8423 57.9648 51.6563C42.0696 49.6672 31.0517 39.3054 31 24.645C31 14.5623 36.9572 7.75 45.2083 7.75ZM14.2083 7.75C17.9766 7.75 21.5906 9.24695 24.2551 11.9115C26.9197 14.5761 28.4167 18.1901 28.4167 21.9583C28.4167 27.7553 25.3993 33.3612 20.8966 34.4436C19.158 34.8621 17.7759 37.0915 18.073 39.6361C18.4708 43.0409 21.5502 46.4819 27.6494 49.1944C29.0082 49.7989 28.4399 51.8423 26.9648 51.6563C11.0696 49.6672 0.0516667 39.3054 0 24.645C0 14.5623 5.95717 7.75 14.2083 7.75Z" fill="#FF9822"/>
        </g>
        <defs>
        <clipPath id="clip0_70_800">
            <rect width="62" height="62" fill="white"/>
        </clipPath>
        </defs>
    </svg>
  )
}

export default QuoteIcon