import React from "react";
import PageBanner from "../../layout/PageBanner";
import bannerUrl from "../../assets/images/contact-us.jpeg";
import ContactImg from "../../assets/images/email-photo.png";

const PrivacyPolicy = () => {
  return (
    <div className="contact-us-page">
      <PageBanner bgUrl={bannerUrl} title={"Privacy Policy"} />
      <div className="flex flex-col md:flex-row items-center pt-[60px] gap-[50px] md:pt-[100px] pb-[80px] md:pb-[120px] content-container">
        <div className="contact-img">
          <img src={ContactImg} alt="Contact" />
        </div>
        <div className="privacy-policy-page">
          <p>
            Your privacy is important to us. It is our policy to respect your
            privacy regarding any information we may collect from you through
            our App, Quran: Authorized English.
          </p>
          <br />
          <h5 className="text-primary uppercase text-center font-normal">
            Information we collect
          </h5>
          <p>
            We only ask for personal information when we truly need it to
            provide a service to you. We collect it by fair and lawful means,
            with your knowledge and consent. We also let you know why we’re
            collecting it and how it will be used.
          </p>
          <br />
          <h5 className="text-primary uppercase text-center font-normal">
            Log data
          </h5>
          <p>
            When you access our servers via the App, we may automatically log
            standard data provided by your web client. It may include your
            device’s Internet Protocol (IP) address, your device type and
            version, the pages you visit, the time and date of your visit, the
            time spent on each page, and other details.
          </p>
          <br />
          <h5 className="text-primary uppercase text-center font-normal">
            Data storage and sharing
          </h5>
          <p>
            Relevant data is stored securely on our servers and managed services
            provided by Google Cloud. It is only accessed by our staff for the
            purpose of providing you with the services of the App.
          </p>
          <br />
          <h5 className="text-primary uppercase text-center font-normal">
            Changes to this policy
          </h5>
          <p>
            At our discretion, we may change our privacy policy to reflect
            current acceptable practices. We will take reasonable steps to let
            users know about changes via our website. Your continued use of this
            site after any changes to this policy will be regarded as acceptance
            of our practices around privacy and personal information.
          </p>
          <br />
          <h5 className="text-primary uppercase text-center font-normal">
            Contact us
          </h5>
          <p>
            For any questions about how we handle user data and personal
            information, feel free to contact us at worshipGodalone@gmail.com.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
