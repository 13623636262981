import React from 'react'
import { Link } from 'react-router-dom'

const LinkButton = ({btnLink, btnText}) => {
  return (
    <Link to={btnLink} className='flex items-center border-[1px] border-[#FF9822] rounded-[10px] px-[27px] py-[13px] text-[10px] md:text-[14px] lg:text-[16px] text-black font-medium leading-normal gap-[10px]'>
        {btnText}
        <span>
            <svg width="31" height="28" viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.1642 13.7667L0.638151 3.0975C0.0645399 2.44194 -0.00716145 1.76181 0.423047 1.05708C0.853255 0.352361 1.58051 0 2.60482 0C3.01454 0 3.40378 0.07375 3.77253 0.22125C4.14128 0.36875 4.42808 0.573611 4.63294 0.835833L16.1871 13.7667L4.63294 26.6975C4.42808 26.9597 4.14128 27.1646 3.77253 27.3121C3.40378 27.4596 3.01454 27.5333 2.60482 27.5333C1.62148 27.5333 0.90447 27.181 0.453776 26.4762C0.00308159 25.7715 0.0645399 25.0914 0.638151 24.4358L10.1642 13.7667ZM24.7913 13.7667L15.2652 3.0975C14.6916 2.44194 14.6199 1.76181 15.0501 1.05708C15.4803 0.352361 16.2076 0 17.2319 0C17.6416 0 18.0309 0.07375 18.3996 0.22125C18.7684 0.36875 19.0552 0.573611 19.26 0.835833L30.8142 13.7667L19.26 26.6975C19.0552 26.9597 18.7684 27.1646 18.3996 27.3121C18.0309 27.4596 17.6416 27.5333 17.2319 27.5333C16.2486 27.5333 15.5316 27.181 15.0809 26.4762C14.6302 25.7715 14.6916 25.0914 15.2652 24.4358L24.7913 13.7667Z" fill="#FF9822"/>
            </svg>
        </span>
    </Link>
  )
}

export default LinkButton