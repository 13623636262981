import React from 'react';

const CloseIcon = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_266_2187" masktype="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
      <rect width="30" height="30" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_266_2187)">
      <path d="M15 16.3169L8.65861 22.6582C8.48555 22.8313 8.268 22.9199 8.00598 22.9239C7.74398 22.9279 7.52244 22.8393 7.34136 22.6582C7.16025 22.4772 7.0697 22.2576 7.0697 21.9996C7.0697 21.7416 7.16025 21.5221 7.34136 21.341L13.6827 14.9996L7.34136 8.65824C7.16827 8.48518 7.07973 8.26764 7.07573 8.00562C7.07171 7.74362 7.16025 7.52208 7.34136 7.34099C7.52244 7.15989 7.74198 7.06934 7.99998 7.06934C8.25798 7.06934 8.47752 7.15989 8.65861 7.34099L15 13.6823L21.3414 7.34099C21.5144 7.16791 21.732 7.07937 21.994 7.07537C22.256 7.07135 22.4775 7.15989 22.6586 7.34099C22.8397 7.52208 22.9303 7.74162 22.9303 7.99962C22.9303 8.25762 22.8397 8.47716 22.6586 8.65824L16.3173 14.9996L22.6586 21.341C22.8317 21.5141 22.9202 21.7316 22.9242 21.9936C22.9283 22.2556 22.8397 22.4772 22.6586 22.6582C22.4775 22.8393 22.258 22.9299 22 22.9299C21.742 22.9299 21.5224 22.8393 21.3414 22.6582L15 16.3169Z" fill="#1C1B1F"/>
    </g>
  </svg>
);

export default CloseIcon;
