import React from 'react'
import { Link } from 'react-router-dom'
import PageBanner from '../../layout/PageBanner'
import bannerImg from '../../assets/images/proclaimationImg.png'
import rashadImg from '../../assets/images/rashad-arabic.png'
import rashadaImg from '../../assets/images/rashada-arabic.png'
import msngrArabicImg from '../../assets/images/messenger-will-come-arabic.png' 
import msngrHeChoosesImg from '../../assets/images/messenger-He-chooses-arabic.png'
import choosenMsngrImg from '../../assets/images/chosen-messenger-arabic.png'
import LinkButton from '../../components/Buttons/LinkButton'
import LinkButtonPrev from '../../components/Buttons/LinkButtonPrev'
import SubPageHeader from '../../components/SubPageHeader'
import './index.scss'

const AppendixTwo = () => {
  return (
    <div className='messengerOfTheCoveant-page'>
        <PageBanner bgUrl={bannerImg } longTitle={"God's Messenger of the Covenant"}/>
        <div className='sub-page-content content-container py-6 md:py-12'>
            <SubPageHeader/>
          
            <div className='page-title mb-8'>
                <h4>Appendix 2 - God's Messenger of the Covenant</h4>
            </div>
            <div className='p-4 rounded-[10px] border-[1px] border-primary mx-auto max-w-screen-lg mb-8'>
              <p>
                God's Messenger of the Covenant is a consolidating messenger. His mission is to purify and unify all existing religions into one: Islam (Submission).
                <br/><br/>
                Islam is NOT a name; it is a description of one's total submission and devotion to God ALONE, without idolizing Jesus, Mary, Muhammad, or the saints. Anyone who meets this criterion is a "Muslim" (Submitter). Therefore, one may be a Muslim Jew, a Muslim Christian, a Muslim Hindu, a Muslim Buddhist, or Muslim Muslim.
                <br/><br/>
                God's Messenger of the Covenant delivers God's proclamation that "The only religion approved by God is Submission" (3:19) and that "Anyone who seeks other than Submission as a religion, it will not be accepted from him/her" (3:85).
                <br/><br/>
                A messenger of God must present proof that he is God's messenger. Every messenger of God is supported by incontrovertible divine signs proving that he is authorized by the Almighty to deliver His messages. Moses threw down his staff and it turned into a serpent, Jesus healed the leprous and revived the dead by God's leave, Saaleh's sign was the famous camel, Abraham walked out of the fire, and Muhammad's miracle was the Quran (29:50-51).
                <br/><br/>
                The Quran (3:81, 33:7, 33:40) and the Bible (Malachi 3:1-3) have prophesied the advent of the consolidating messenger, God's Messenger of the Covenant. It is only befitting that a messenger with such a crucial mission must be supported by the most powerful miracle (74:30-35). While the miracles of previous messengers were limited in time and place, God's miracle supporting His Messenger of the Covenant is perpetual; it can be witnessed by anyone, at anytime, in any place.
                <br/><br/>
                This Appendix presents physical, examinable, verifiable, and irrefutable evidence that Rashad Khalifa is God's Messenger of the Covenant.
              </p>
            </div>

            <h5 className='my-3 text-black'>A Quranic Truth</h5>
            <p>
              One of the major prophecies in the Quran is that God's Messenger of the Covenant will be sent after all the prophets have come to this world, and after all of God's scriptures have been delivered.
            </p>
            <div className='p-4 rounded-[10px] border-[1px] border-primary mx-auto max-w-screen-md my-5'>
              <p>
                God took a covenant from the prophets, saying, "I will give you the scripture and wisdom. Afterwards, a messenger will come to confirm all existing scriptures. You shall believe in him and support him." He said, "Do you agree with this, and pledge to fulfill this covenant?" They said, "We agree." He said, "You have thus borne witness, and I bear witness along with you." [3:81]
              </p>
            </div>
            <p>
              Muhammad Marmaduke Pickthall translated 3:81 as follows:
            </p>
            <div className='p-4 rounded-[10px] border-[1px] border-primary mx-auto max-w-screen-md my-5'>
              <p>
                When Allah made (His) covenant with the Prophets, (He said): Behold that which I have given you of the Scripture and knowledge. And afterward there will come unto you a messenger, confirming that which ye possess. Ye shall believe in him and ye shall help him. He said: Do ye agree, and will ye take up My burden (which I lay upon you) in this (matter)? They answered: We agree. He said: Then bear witness. I will be a witness with you.
              </p>
            </div>
            <p>
              We learn from Sura 33 that Muhammad was one of the prophets who made that solemn covenant with God.
            </p>
            <div className='p-4 rounded-[10px] border-[1px] border-primary mx-auto max-w-screen-md my-5'>
              <p>
                And when we exacted a covenant from the Prophets, and from thee (O Muhammad) and from Noah and Abraham and Moses and Jesus son of Mary, We took from them a solemn covenant. [33:7] (according to Muhammad Marmaduke Pickthall)
              </p>
            </div>
            <p>
              Verse 3:81, among many other verses, provides the definitions of "Nabi" (Prophet) and "Rasoul" (Messenger). Thus, "Nabi" is a messenger of God who delivers a new scripture, while "Rasoul" is a messenger commissioned by God to confirm existing scripture; he does not bring a new scripture. According to the Quran, every "Nabi" is a "Rasoul," but not every "Rasoul" is a "Nabi."
              <br/><br/>
              Not every messenger was given a new scripture. It is not logical that God will give a scripture to a prophet, then ask him to keep it exclusively for himself, as stated by some Muslim "scholars" (2:42, 146, 159). Those who are not sufficiently familiar with the Quran tend to think that Aaron was a "Nabi," as stated in 19:53, who did not receive a scripture. However, the Quran clearly states that the statute book was given specifically "to both Moses and Aaron" (21:48, 37:117).
              <br/><br/>
              We learn from the Quran, 33:40, that Muhammad was the last prophet (Nabi), but not the last messenger (Rasoul):
            </p>
            <div className='p-4 rounded-[10px] border-[1px] border-primary mx-auto max-w-screen-md my-5'>
              <p>
                Muhammad was not the father of any of your men; he was a messenger (Rasoul) of God and the last prophet (Nabi). [ 33:40 ]
              </p>
            </div>
            <p>
              This crucial definition is confirmed by the Quran's mathematical code. The expression used in 33:40, "Muhammad Khaatum Al-Nabiyyeen" (the last prophet) has a gematrical value of 1349, 19x71, while the value of the erroneous expression "Muhammad Khaatum Al-Mursaleen" (the last messenger) is not a multiple of 19.
              <br/><br/>
              From time immemorial, it has been a human trait to reject a contemporary, living messenger. Joseph was declared "the last messenger" (40:34). Yet, many messengers came after him, including Moses, David, Solomon, Jesus, and Muhammad.
            </p>

            <h5 className='my-3 text-black'>The Covenant Fulfilled</h5>
            <p>
              Although the prophets are dead, as far as this world is concerned, we know that their souls, the real persons, are now in the Garden of Eden where Adam & Eve lived. Several verses enjoin us from thinking that the believers who shed their bodies and departed this world are dead (2:154, 3:169, 4:69). Although they cannot come back to our world (23:100), they are "alive" in Paradise. Please see Appendix 17.
              <br/><br/>
              During my Hajj pilgrimage to Mecca, and before sunrise on Tuesday, Zul-Hijjah 3, 1391, December 21, 1971, I, Rashad Khalifa, the soul, the real person, not the body, was taken to some place in the universe where I was introduced to all the prophets as God's Messenger of the Covenant. I was not informed of the details and true significance of this event until Ramadan 1408.
              <br/><br/>
              What I witnessed, in sharp consciousness, was that I was sitting still, while the prophets, one by one, came towards me, looked at my face, then nodded their heads. God showed them to me as they had looked in this world, attired in their respective mode of dress. There was an atmosphere of great awe, joy, and respect.
              <br/><br/>
              Except for Abraham, none of the prophets was identified to me. I knew that all the prophets were there, including Moses, Jesus, Muhammad, Aaron, David, Noah, and the rest. I believe that the reason for revealing Abraham's identity was that I asked about him. I was taken aback by the strong resemblance he had with my own family - myself, my father, my uncles. It was the only time that I wondered, "Who is this prophet who looks like my relatives?" The answer came: "Abraham." No language was spoken. All communication was done mentally.
              <br/><br/>
              It is noteworthy that the date of this fulfillment of the prophets' covenant was Zul-Hijja 3, 1391. If we add the month (12), plus the day (3), plus the year (1391), we get a total of 1406, 19x74. Sura 74 is where the Quran's common denominator, the number 19, is mentioned. Note that the number 1406 is also the number of years from the revelation of the Quran to the revelation of its miracle (Appendix 1).
              <br/><br/>
              The mission of God's Messenger of the Covenant is to confirm existing scriptures, purify them, and consolidate them into one divine message. The Quran states that such a messenger is charged with restoring God's message to its pristine purity, to lead the righteous believers - Jews, Christians, Muslims, Buddhists, Sikhs, Hindus, and others - out of darkness into the light (5:19 & 65:11). He is to proclaim that Islam (total submission to God) is the only religion acceptable by God (3:19).
            </p>
            <div className='p-4 rounded-[10px] border-[1px] border-primary mx-auto max-w-screen-md my-5'>
              <p>
                "Lo, I am sending my messenger to prepare the way before me; and suddenly there will come to the temple the Lord whom you seek and the messenger of the covenant whom you desire. Yes, he is coming, says the Lord of hosts. But who will endure the day of his coming? And who can stand when he appears? For he is like the refiner's fire, or like the fuller's lye." [Malachi 3:1-2]
              </p>
            </div>

            <h5 className='my-3 text-black'>The Proof</h5>
            <div className='flex flex-col lg:flex-row gap-5'>
              <div className='basis-3/5'>
                <p>
                  The name of God's Messenger of the Covenant is mathematically coded into the Quran as "Rashad Khalifa." This is certainly the most appropriate method of introducing God's messenger to the world in the computer-age.
                </p>
                <p>
                  (1) As shown in Appendix 1, God's great miracle in the Quran is based on the prime number 19, and it remained hidden for 1406 years (19x74). This awesome miracle was predestined by Almighty God to be unveiled through Rashad Khalifa. Hundreds of Muslim and Orientalist scholars during the last 14 centuries have tried in vain, but none of them was permitted to decipher the significance of the Quranic Initials.
                </p>
                <p>
                  (2) The Quran is made easy for the sincere believers and seekers (54:17, 22, 32, 40 & 39:28). It is an irrevocable divine law that no one is permitted access to the Quran, let alone its great miracle, unless he or she is a sincere believer who is given specific divine authorization (17:45-46, 18:57, 41:44, 56:79). The unveiling of the Quran's miracle through Rashad Khalifa is a major sign of his messengership.
                </p>
                <p>
                  (3) The root word of the name "Rashad 
                  <img src={rashadImg} alt='Rashad in Arabic' className="h-[25px] inline mx-1"/>" is "Rashada 
                  <img src={rashadaImg} alt='Rashada in Arabic' className="h-[25px] inline mx-1"/>" 
                  (to uphold the right guidance). This root word is mentioned in the Quran 19 times. Nineteen is the Quran's common denominator (see INDEX TO THE WORDS OF QURAN, First Printing, Page 320).
                </p>
              </div>
              <div className='basis-2/5'>
                <h5 className='my-3 text-black text-center mt-10 italic'>Table 1: Suras and Verses of "Rashada" and "Khalifa"</h5> 
                <div className='table-con my-5 max-w-screen-md mx-auto'>
                  <table className='w-full border-0'>
                    <thead className='bg-primary'>
                      <tr>
                        <th style={{ width: '60px' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Sura</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase' colSpan={2}>"Rashada"</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase' colSpan={2}>"Khalifa"</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>No.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Sura</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Verse</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Sura</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Verse</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>186</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>(2)</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>30</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>256</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>38</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>26</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7	</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>146</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>11</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>78</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>87</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-	</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>97</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>18</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>10</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>17</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>10.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-	</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>24</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>11.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>66</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>12.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>21</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>51</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>13.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>40</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>29</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>14.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>38</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>15.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>49</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>16.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>72</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>17.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>10</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>18.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>14</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-center' colSpan={2}>(Sura 2 is a Repeat)</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>224</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1145</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>38</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>56</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-center' colSpan={5}>224 + 1145 + 38 + 56 = 1463 = 19 x 77</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className='flex flex-col lg:flex-row gap-5'>
              <div className='basis-3/5'>
                <p>
                  (4) The word "Rashad" occurs in 40:29 & 38. The word "Khalifa" occurs in 2:30 and 38:26. The first "Khalifa" refers to a non-human "Khalifa," namely, Satan, while the second occurrence (Sura 38), refers to a human "Khalifa." If we add the numbers of suras and verses of "Rashad" (40:29, 38) and "Khalifa" (38:26) we get 40+29+38+38+26 = 171 = 19x9.
                </p>
                <p>
                  (5) The sum of all sura and verse numbers where all "Rashada" and all "Khalifa" occur, without discrimination, add up to 1463, 19x77 (Table 1).
                </p>
                <p>
                  (6) The total of all suras and verses where the root word "Rashada" occurs is 1369, or (19x72)+1, while the total for all occurrences of "Khalifa" is 94, (19x5)-1. The fact that "Rashada" is up by one and "Khalifa" is down by one pins down the name as "Rashad Khalifa," and not any "Rashad" or any "Khalifa."
                </p>
                <p>
                  (7) The gematrical value of "Rashad" is 505 and the value of "Khalifa" is 725 (Table 7, Appendix 1). If we add the value of "Rashad Khalifa" (1230) to the sura numbers, and the number of verses, from the beginning of the Quran to the first occurrence of "Rashada," the total is 1425, 19x75. The details are given in Table 2.
                </p>
                <p>
                  (8) If we add the numbers of all the verses in every sura, i.e., the sum of verse numbers (1+2+3+ ...+n) from the beginning of the Quran to the first occurrence of the root word "Rashada," the total comes to 17233, 19x907 (Table 2).
                </p>
                <p>
                  (9) The Quranic Initials constitute the basic foundation of the Quran's miracle. These initials occur in suras 2, 3, 7, 10, 11, 12, 13, 14, 15, 19, 20, 26, 27, 28, 29, 30, 31, 32, 36, 38, 40, 41, 42, 43, 44, 45, 46, 50, and 68. If we add the sum of these numbers (822) to the value of "Rashad Khalifa" (1230), the total is 2052, 19x108.
                </p>
                <p>
                  (10) As shown in Table 3, if we add the numbers of all suras where the root word "Rashada" occurs, plus the number of verses, we get 1368, or 19x72.
                </p>
              </div>
              <div className='basis-2/5'>
              <h5 className='my-3 text-black text-center mt-10 italic'>Table 2: The Suras and Verses from the beginning of the Quran to the First occurence of the Root Word "Rashada"</h5> 
                <div className='table-con my-5 max-w-screen-md mx-auto'>
                  <table className='w-full border-0'>
                    <thead className='bg-primary'>
                      <tr>
                        <th style={{ width: '25%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>No. of Verses</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Sum of Verse #s</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>28</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>185</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>17205</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>192</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>17233</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>(19x907)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className='p-4 rounded-[10px] border-[1px] border-primary mx-auto max-w-screen-md my-5'>
                  <p>
                    Also, "Rashad" (505) + "Khalifa" (725) + Sura Total (3) + Total of Verses (192) = 1425 (19x75)<br/>
                    505 + 725 + 3 + 192 = 1425 = 19x75
                  </p>
                </div>
                <h5 className='my-3 text-black text-center mt-10 italic'>Table 3: The Suras Where the Root Word "Rashada" Occurs.</h5> 
                <div className='table-con my-5 max-w-screen-md mx-auto'>
                  <table className='w-full border-0'>
                    <thead className='bg-primary'>
                      <tr>
                        <th style={{ width: '25%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>No. of Verses</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>286</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>288</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>176</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>180</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>206</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>213</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>11</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>123</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>134</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>18</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>110</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>128</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>21</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>112</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>133</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>40</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>85</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>125</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>49</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>18</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>67</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>72</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>28</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>100</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='p-4 text-black text-[12px] md:text-[14px] text-start font-mmedium'>224</td>
                        <td className='p-4 text-black text-[12px] md:text-[14px] text-start font-mmedium'>1144</td>
                        <td className='p-4 text-black text-[12px] md:text-[14px] text-start font-mmedium'>1368</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-end' colSpan={3}>(19x72)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <p>
              (11) If we write down the sura number, followed by the number of verses per sura, followed by the individual verse numbers, from the first occurr- ence of the root word "Rashada" (2:186) to the last occurrence of "Rashada" (72:21), and place these numbers next to each other, we get a very long number that consists of 11087 digits, and is a multiple of 19. This very long number begins with the number of Sura 2, followed by the number of verses in Sura 2 from the first occurrence of "Rashada" at verse 186 to the end of the sura (100 verses). Thus, the beginning of the number looks like this: 2 100. The numbers of these 100 individual verses (187 to 286) are placed next to this number. Thus, the number representing Sura 2 looks like this: 2 100 187 188 189 ..... 285 286. The same process is carried out all the way to 72:21, the last occurrence of the root "Rashada." The complete number looks like this:
            </p>
            <div className='rounded-[10px] border-[1px] border-primary mx-auto max-w-screen-md my-5'>
              <p className='p-4 text-center border-b-[1px] border-primary'>
                2 100 187 188 189 ..... 72 21 1 2 3 ..... 19 20 21
              </p>
              <p className='p-4 '>
                The Sura number is followed by the number of verses, then the numbers of individual verses, from the first to the last occurrence of "Rashada" (2:187 through 72:21). The complete number consists of 11087 digits, and is divisible by 19.
              </p>
            </div>
            <div className='flex flex-col lg:flex-row gap-5'>
              <div className='basis-3/5'>
                <p>
                  (12) If we examine the suras and verses from the first occurrence of the root word "Rashada" to the word "Khalifa" in 38:26, we find that the sum of sura numbers and their numbers of verses is 4541, or 19x239. The details are in Table 4.
                </p>
                <p>
                  (13) When we write down the value of "Rashad" (505), followed by the value of "Khalifa" (725), followed by every sura number where the root word "Rashada" occurs, followed by the numbers of its verses, from the first "Rashada" (2:186) to the word "Khalifa" (38:26), we get a long number that is divisible by 19.
                </p>
                <p>
                  The first occurrence of "Rashada" is in 2:186. So, we write down 2 186. The second occurrence is in 2:256, so we write down 256. The next occurrence is in 4:6, so we write down 4 6, and so on, until we write down 38 26 ("Khalifa" occurs in 38:26). The complete number lookslike this:
                </p>
              </div>
              <div className='basis-2/5'>
                <h5 className='my-3 text-black text-center mt-10 italic'>Table 4: The Suras and Verses from the 1st "Rashada" to "Khalifa."</h5> 
                <div className='table-con my-5 max-w-screen-md mx-auto'>
                  <table className='w-full border-0'>
                    <thead className='bg-primary'>
                      <tr>
                        <th style={{ width: '25%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>No. of Verses</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>100 (187-286)</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>102</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>200</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>203</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>176</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>180</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>120</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>125</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>36</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>83</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>119</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>37</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>182</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>219</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>38</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>26</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>64</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='p-4 text-black text-[12px] md:text-[14px] text-start font-medium'>740</td>
                        <td className='p-4 text-black text-[12px] md:text-[14px] text-start font-medium'>3801</td>
                        <td className='p-4 text-black text-[12px] md:text-[14px] text-start font-medium'>4541</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>(19x239)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className='rounded-[10px] border-[1px] border-primary mx-auto max-w-screen-md my-5'>
              <p className='p-4 text-center border-b-[1px] border-primary'>
                505 725 2 186 256 4 6 ............... 38 26
              </p>
              <p className='p-4 '>
                The gematrical value of "Rashad" is followed by the value of "Khalifa," followed by the sura number and verse numbers of every occurrence of the root word "Rashada" from the first occurrence of "Rashada" to the occurrence of "Khalifa" in 38:26.
              </p>
            </div>

            <h5 className='my-3 text-black'>The Only Religion Approved by God is Islam [3:19]</h5>
            <p>(14) The Quran specifies three messengers of Islam (Submission):</p>
            <div className='table-con my-5 max-w-screen-md mx-auto'>
                  <table className='w-full border-0'>
                    <tbody>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Abraham delivered all the practices of Islam. The value of his name</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>= 258</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Muhammad delivered the Quran. The value of his name</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>= 92</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Rashad delivered Islam's proof of authenticity. The value of his name</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>= 505</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Total gematrical value of the 3 names = 258 + 92 + 505</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>= 855</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>(19x45)</td>
                      </tr>
                    </tbody>
                  </table>
            </div>

            <p>
              The true Judaism, Christianity, and Islam will be consolidated into one religion - complete submission and absolute devotion to God ALONE.
              <br/><br/>
              The existing religions, including Judaism, Christianity, and Islam are severely corrupted and will simply die out (9:33, 48:28, 61:9).
            </p>

            <p>
              (15) Since the Quran sometimes refers to "Abraham, Ismail, and Isaac," it was suggested that Ismail and Isaac should be included. Remarkably, the addition of Ismail and Isaac gave a total that is still a multiple of 19. As shown in Table 5, the new total is 1235, or 19x65. This divisibility by 19 is not possible if any of the 3 names Abraham, Muhammad, or Rashad is omitted.
            </p>

            <div className='flex flex-col lg:flex-row gap-5'>
              <div className='basis-3/5'>
                <h5 className='my-3 text-black'>Why 81: Verse 81 & Sura 81</h5>
                <p>
                  (16) God's Messenger of the Covenant is prophesied in Verse 81 of Sura 3. The addition of the gematrical value of "Rashad" (505), plus the gematrical value of "Khalifa" (725), plus the Verse number (81), produces 505 + 725 + 81 = 1311 = 19x69.
                </p>
                <p>
                  (17) If we look at Sura 81, we read about a messenger of God who is powerfully supported and authorized by the Almighty (Verse 19). Thus, Verse 81 of Sura 3, and Sura 81, Verse 19 are strongly connected with the name "Rashad Khalifa" 505 + 725 + 81 = 1311 = 19x69
                </p>
                <p>
                  (18) If we add the sura numbers plus the number of verses from the beginning of the Quran to Verse 3:81, where the Messenger of the Covenant is prophesied, the total comes to 380, 19x20. These data are in Table 6.
                </p>
                <p>
                  (19) The gematrical value of Verse 3:81 is 13148, 19x692. This value is obtained by adding the gematrical values of every letter in the verse.
                </p>
              </div>
              <div className='basis-2/5'>
                <h5 className='my-3 text-black text-center mt-10 italic'>Table 5: Gematrical Value of the 5 Messengers</h5> 
                <div className='table-con my-5 max-w-screen-md mx-auto'>
                  <table className='w-full border-0'>
                    <thead className='bg-primary'>
                      <tr>
                        <th style={{ width: '25%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Name</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Value of Individual Letters</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Abraham</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1 + 2 + 200 + 5 + 10 + 40</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>258</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Ismail</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1 + 60 + 40 + 70 + 10 + 30</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>211</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Isaac</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1 + 60 + 8 + 100</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>169</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Muhammad</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>40 + 8 + 40 + 4</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>92</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Rashad</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>200 + 300 + 1 + 4</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>505</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1235</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1235</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>(19x65)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h5 className='my-3 text-black text-center mt-10 italic'>Table 6: The Suras and Verses from 1:1 to 3:81</h5> 
                <div className='table-con my-5 max-w-screen-md mx-auto'>
                  <table className='w-full border-0'>
                    <thead className='bg-primary'>
                      <tr>
                        <th style={{ width: '25%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>No. of Verses</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>286</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>288</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>81</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>84</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>374</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>380</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>(19x20)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <p>
              (20) If we look at that portion of Verse 3:81 which refers specifically to the messenger of the Covenant: "A messenger will come to you, confirming what you have," in Arabic:
            </p>

            <div className='my-5 text-center'>
              <p className='m-0'>"JAA'AKUM RASOOLUN MUSADDIQUN LEMAA MA`AKUM"M</p>
              <img src={msngrArabicImg} alt='Messenger will come [3:81]' className='object-contain mx-auto'/>
            </div>

            <div className='flex flex-col lg:flex-row gap-5'>
              <div className='basis-3/5'>
                <p>we find that the gematrical value of this key phrase is 836, 19x44.</p>
                <h5 className='my-3 text-black'>"Surely, You Are One of the Messengers" (36:3)</h5>
                <p>(21) I was told most assertively, through the angel Gabriel, that Verse 3 of Sura 36 refers specifically to me. If we arrange the initialed suras only, starting with Sura 2, then Sura 3, then Sura 7, and so on, we find that Sura 36, Ya Seen, occupies position number 19.</p>
                <p>(22) Verse 3 of Sura 36 says, "Surely, you are one of the messengers." The gematrical value of this phrase is 612. By adding this value (612), plus the sura number (36), plus the verse number (3), plus the gematrical value of "Rashad Khalifa" (505+725), we get 36+3+612+505 +725 = 1881 = 19x99.</p>
                <p>(23) Sura 36 consists of 83 verses. If we add the sura number (36), plus its number of verses (83), plus the gematrical value of "Rashad Khalifa" (505+725), we get 36+83+505+725 = 1349 = 19x71.</p>
                <p>(24) From 3:81, where the Messenger of the Covenant is prophesied, to Sura 36, there are 3330 verses. By adding the value of "Rashad Khalifa" (1230), to this number of verses (3330), we get 505+725+3330 = 4560, 19x240.</p>
                <p>(25) From 3:81 to 36:3 there are 3333 verses. By adding this number to the gematrical value of "Rashad" (505),we get 3333+505 = 3838 = 19x202.</p>
                <p>(26) The number of verses from 1:1 to 36:3 is 3705, 19x195 (Table 7).</p>
                <p>(27) The sum of verse numbers of every sura from 1:1 to 36:3 is 257925, 19x13575 (Table 7).</p>
                <p>(28) The sum of sura numbers from Sura 1 to Sura 36 is 666 (Table 7). If we add this sum to the gematrical value of "Rashad Khalifa" (505+725), plus the gematrical value of verse 36:3 "Surely, you are one of the messengers," (612), the total is: 666 + 505 + 725 + 612 = 2508 = 19x132.</p>
                <p>(29) If we add the sum of verse numbers(1+2+3+ ...+n) from the first occurrence of the root word "Rashada" (2:186) to 36:3 (You are one of the messengers) to the total of suras (35), plus the sura numbers themselves, the total is 241395, or 19x12705 (Table 8).</p>
                <p>(30) The sum of sura numbers from the the first occurrence of the root word "Rashada" to 36:3 is 665, 19x35. Note that these are 35 suras (Table 8).</p>

              </div>
              <div className='basis-2/5'>
                <h5 className='my-3 text-black text-center mt-10 italic'>Table 7: Suras and Verses From Sura 1 to Verse 3 of Sura 36.</h5> 
                <div className='table-con my-5 max-w-screen-md mx-auto'>
                  <table className='w-full border-0'>
                    <thead className='bg-primary'>
                      <tr>
                        <th style={{ width: '25%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>No. of Verses</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Sum of Verse #s</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>28</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>286</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>41041</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>200</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20100</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>127</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8128</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>34</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>54</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1485</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>35</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>45</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1035</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>36</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>666</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3705</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>257925</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>(19x195)</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>(19x13575)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h5 className='my-3 text-black text-center mt-10 italic'>Table 8: The Suras and Verses from the First "Rashada" to 36:3.</h5> 
                <div className='table-con my-5 max-w-screen-md mx-auto'>
                  <table className='w-full border-0'>
                    <thead className='bg-primary'>
                      <tr>
                        <th style={{ width: '25%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>No. of Verses</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Sum of Verse #s</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1.	</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2 (186-286)</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>23836</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20100</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>15576</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7260</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>10.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8128</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>33.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>34</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1485</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>34.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>35</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1035</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>35.</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>36 (1-3)</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>35</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>665</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>240695</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-center' colSpan={3}>35 + 665 + 240695 = 241395 (19x12705)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <p>"A Messenger to the People of the Scripture" (Jews, Christians, and Muslims)</p>
            <div className='p-4 rounded-[10px] border-[1px] border-primary mx-auto max-w-screen-md my-5'>
              <p>
                O people of the scripture, our messenger has come to you, to clarify things for you, after a long period without messengers. Lest you say, "No preacher or warner has come to us." A preacher and warner has come to you. God is Omnipotent. [5:19]
              </p>
            </div>

            <div className='flex flex-col lg:flex-row gap-5'>
              <div className='basis-2/3'>
                <p>(31) Obviously, the number of this verse is 19, the Quran's common denominator discovered by Rashad, and the number of occurrences of "Rashada" in the Quran.</p>
                <p>(32) If we add the value of "Rashad Khalifa" (1230), plus the sura number (5), plus the verse number (19), we get 1230+5+19 = 1254 = 19x66</p>
                <p>(33) The sum of the sura numbers and the number of verses from the beginning of the Quran to this verse (5:19) is 703, 19x37. See Table 9.</p>
                <p>(34) Sura 98, "The Proof," Verse 2, proclaims the advent of God's Messenger of the Covenant for the benefit of "The People of the Scripture (Jews, Christians, and Muslims)." By adding the gematrical value of "Rashad Khalifa" (505+725) to the sura number (98), plus the verse number (2), we get: 505 + 725 + 98 + 2 = 1330 = 19x70.</p>
              </div>
              <div className='basis-1/3'>
                <h5 className='my-3 text-black text-center mt-10 italic'>Table 9: The Suras and Verses from the Beginning to 5:19.</h5> 
                <div className='table-con my-5 max-w-screen-md mx-auto'>
                  <table className='w-full border-0'>
                    <thead className='bg-primary'>
                      <tr>
                        <th style={{ width: '25%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>No. of Verses</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Sum of Verse #s</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1.	</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2 (186-286)</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>23836</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className='p-4 rounded-[10px] border-[1px] border-primary mx-auto max-w-screen-md my-5'>
              <p>
              Those who disbelieved among the people of the scripture (Jews, Christians, Muslims), and the idolators, will not believe, despite the profound sign given to them. [98:1]
              </p>
              <h5 className='my-3 text-black text-center mt-10 italic'>A messenger from God, reciting Sacred Scriptures. [98:2]</h5> 

            </div>

            <p>(35) It is noteworthy that the word "Bayyinah," which means "Profound Sign," and is the title of Sura 98, occurs in the Quran 19 times. This is another mathematical confirmation that the Quran's proof of divine authorship is based on the prime number 19, and that "Rashad Khalifa" is the messenger in 98:2.</p>

            <h5 className='my-3 text-black'>A Profound Messenger Has Come [44:13]</h5>
            <p>(36) By adding the sura numbers, plus the number of verses in each sura, from the 1:1 to 44:13, the total comes to 5415, 19x19x15 (Table 10).</p>
            <p>(37) The sum of the sura number (44) plus the number of the verse where the messenger is predicted (13) equals 57, 19x3. See Table 10.</p>

            <div className='flex flex-col lg:flex-row gap-5'>
              <div className='basis-2/3'>
                <h5 className='my-3 text-black'>END OF THE WORLD</h5>
                <p>(38) God is the only Knower of the future; He knows exactly when this world will end (7:187, 31:34, 33:63, 41:47, 43:85). We learn from the Quran that God reveals certain aspects of the future to His chosen messengers. In Appendix 25, evidence is presented that Rashad Khalifa was blessed with unveiling the End of the World, in accordance with 72:27.</p>

                <p>(39) The number of verses from the beginning of the Quran to Verse 72:27 is 5472, or 19x72x4. Note that the messenger who is given information about the future in 72:27, and that this sura contains 4 "Rashada" words (72:2, 10, 14, & 21). By adding the value of "Rashad Khalifa" (1230), plus the sura number (72), plus the numbers of the 4 verses where "Rashada" is mentioned, we get 1230+72+2+10+14+21 = 1349 = 19x71.</p>      
                <p>
                  (40) Verse 72:27 begins with the statement: 
                  "<img src={msngrHeChoosesImg} alt='Only the Messenger that He chooses' className="h-[25px] inline mx-1"/>" 
                  (Only the Messenger that He chooses). This reference to the messenger who is chosen by God to receive news about the future has a gematrical value of 1919. Table 11 presents the data.
                </p>
              </div>
              <div className='basis-1/3'>
                <h5 className='my-3 text-black text-center mt-10 italic'>Table 10: The Suras and Verses from 1:1 to 44:13</h5> 
                <div className='table-con my-5 max-w-screen-md mx-auto'>
                  <table className='w-full border-0'>
                    <thead className='bg-primary'>
                      <tr>
                        <th style={{ width: '30%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>No. of Verses</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>286</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>288</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>200</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>203</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>176</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>180</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>120</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>125</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>127</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>136</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>41</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>54</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>95</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>42</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>53</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>95</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>43</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>89</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>132</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>44</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>13</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>57</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>990</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4425</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5415</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-end' colSpan={3}>(19x19x15)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <h5 className='my-3 text-black'>HOW TO DISTINGUISH GOD'S MESSENGER FROM A FAKE MESSENGER</h5>
            <div className='flex flex-col lg:flex-row gap-5'>
              <div className='basis-1/3'>
                <h5 className='my-3 text-black text-center mt-10 italic'>Table 11: Gematrical Value of the Chosen Messenger in 72:27.</h5> 
                <img src={choosenMsngrImg} alt='Choosen Messsanger' className='object-contain max-w-[70%] mx-auto'/>
                <div className='table-con my-5 max-w-screen-md mx-auto'>
                  <table className='w-full border-0'>
                    <thead className='bg-primary'>
                      <tr>
                        <th style={{ width: '30%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Letter</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Gematrical Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A	</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>L	</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>30</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A	</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>M	</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>40</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>N</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	50</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A	</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>R</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	200</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>T</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	400</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>D</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	800</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Y</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	10</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>M</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	40</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>N</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	50</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>R</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	200</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>S</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	60</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>W</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	6</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>L</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>30</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1919</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='basis-2/3'>
                <p>
                  The Quran provides straightforward criteria to distinguish the true messengers of God from the false messengers:
                </p>
                <p>[1] God's messenger advocates the worship of God ALONE, and the abolition of all forms of idol worship.</p>
                <p>[2] God's messenger never asks for a wage for himself.</p>
                <p>[3] God's messenger is given divine, incontrovertible proof of his messengership.</p>
                <p>Anyone who claims to be God's messenger, and does not meet the three minimum criteria listed above is a false claimant.</p>
              </div>
            </div>

            <div className='p-4 rounded-[10px] border-[1px] border-primary mx-auto max-w-screen-lg my-5'>
              <p>The most important difference between God's messenger and a fake messenger is that God's messenger is supported by God, while the fake messenger is not:</p>
              <ul className='ms-5 list-disc my-4'>
                <li>God's messenger is supported by God's invisible soldiers (3:124-126, 9:26 & 40, 33:9, 37:171-173, 48:4 & 7, 74:31).</li>
                <li>God's messenger is supported by God's treasury (63:7-8).</li>
                <li>God's messenger, as well as the believers, are guaranteed victory and dignity, in this world and forever (40:51 & 58:21).</li>
              </ul>
            </div>
            <p>Thus, the truthfulness of God's messenger invariably prevails, while the falsehood of a fake messenger invariably, sooner or later, is exposed.</p>

            <h5 className='my-3 text-black'>PRINCIPAL DUTIES OF GOD'S MESSENGER OF THE COVENANT</h5>
            <p>As stated in the Quran, 3:81, God's Messenger of the Covenant shall confirm all the scriptures, which were delivered by all the prophets, and restore them to their original purity.</p>

            <h5 className='my-3 text-black'>MERCY FROM GOD [21:107]</h5>
            <p>When the believers are faced with a problem, they develop a number of possible solutions, and this invariably leads to considerable bickering, disunity, and disarray. We learn from 2:151, 3:164, and 21:107 that it is but mercy from God that He sends to us messengers to provide the final solutions to our problems. We learn from 42:51 that God sends His messengers to communicate with us, and to disseminate new information. Hence the strong injunction in 4:65, 80 to accept, without the slightest hesitation, the teachings delivered to us through God's messengers.</p>

            <p>The following is a list of the principal duties of God's Messenger of the Covenant:</p>
            <div className='p-4 rounded-[10px] border-[1px] border-primary mx-auto max-w-screen-lg my-5'>
              <ul className='ms-5 list-decimal my-4'>
                <li>Unveil and proclaim the Quran's mathematical miracle 
                  <Link to='/chapters/one-of-the-great-miracles' className='underline text-primary'>(Appendix 1)</Link>.
                </li>
                <li>
                  Expose and remove the two false verses 9:128-129 from the Quran
                  <Link to='' className='underline text-primary'>(Appendix 24)</Link>.
                </li>
                <li>
                  Explain the purpose of our lives; why we are here
                  <Link to='' className='underline text-primary'>(Appendix 7)</Link>.
                </li>
                <li>
                  Proclaim one religion for all the people, and point out and purge away all the corruptions afflicting Judaism, Christianity, and Islam
                  <Link to='' className='underline text-primary'>(Appendix 13, 15, 19)</Link>.
                </li>
                <li>
                  Proclaim that Zakat (obligatory charity) is a prerequisite for redemption (7:156), and explain the correct method of observing Zakat
                  <Link to='' className='underline text-primary'>(Appendix 15)</Link>.
                </li>
                <li>
                  Unveil the end of the world
                  <Link to='' className='underline text-primary'>(Appendix 25)</Link>.
                </li>
                <li>
                  Proclaim that those who die before the age of 40 go to Heaven
                  <Link to='' className='underline text-primary'>(Appendix 32)</Link>.
                </li>
                <li>
                  Explain Jesus' death
                  <Link to='' className='underline text-primary'>(Appendix 22)</Link>.
                </li>
                <li>
                  Explain the Quran's delivery to, then through Muhammad
                  <Link to='' className='underline text-primary'>(Appendix 28)</Link>.
                </li>
                <li>
                  Announce that Muhammad wrote God's revelations (the Quran) with his own hand 
                  <Link to='' className='underline text-primary'>(Appendix 28)</Link>.
                </li>
                <li>
                  Explain why most believers in God do not make it to Heaven 
                  <Link to='' className='underline text-primary'>(Appendix 27)</Link>.
                </li>
                <li>
                  Proclaim that God never ordered Abraham to kill his son
                  <Link to='' className='underline text-primary'>(Appendix 9)</Link>.
                </li>
                <li>
                  Proclaim the secret of perfect happiness
                  <Link to='' className='underline text-primary'>(Appendix xx)</Link>.
                </li>
                <li>
                  Establish a criminal justice system
                  <Link to='' className='underline text-primary'>(Appendix 37)</Link>.
                </li>
              </ul>
            </div>

        </div>

        <div className='flex justify-center gap-4 my-6 content-container'>
            <LinkButtonPrev btnLink={'/chapters/appendix-1'} btnText={'One of the Great Miracles [74:35]'}/>
            <LinkButton btnLink={'/chapters/appendix-3'} btnText={'We Made the Quran Easy [54:17]'}/>
        </div>
    </div>
  )
}

export default AppendixTwo