import React from 'react'
import PageBanner from '../../layout/PageBanner'
import bannerImg from '../../assets/images/proclaimationImg.png'
import tashkentImg from '../../assets/images/tashkent.png'
import arranyImg from '../../assets/images/Araany.png'
import haImg from '../../assets/images/Ha.png'
import mimImg from '../../assets/images/Mim.png'
import gvImg from '../../assets/images/GVs.png'
import namesOfGodImg from '../../assets/images/namesofGod.png'
import LinkButton from '../../components/Buttons/LinkButton'
import SubPageHeader from '../../components/SubPageHeader'
import './index.scss'

const AppendixOne = () => {
  return (
    <div className='sub-page'>
        <PageBanner bgUrl={bannerImg } longTitle={'One of the Great Miracles'}/>
        <div className='sub-page-content content-container py-6 md:py-12'>
          <SubPageHeader/>
          
            <div className='page-title mb-8'>
                <h4>Appendix 1 - One of the Great Miracles [74:35]</h4>
            </div>
            <div className='p-4 rounded-[10px] border-[2px] border-primary mx-auto w-[90%] mb-8'>
              <p className='italic text-center font-medium'>
                The Quran is characterized by a unique phenomenon never found in any human authored book. Every element of the Quran is mathematically composed - the suras, the verses, the words, the number of certain letters, the number of words from the same root, the number and variety of divine names, the unique spelling of certain words, the absence or deliberate alteration of certain letters within certain words, and many other elements of the Quran besides its content. There are two major facets of the Quran's mathematical system: (1) The mathematical literary composition, and (2) The mathematical structure involving the numbers of suras and verses. Because of this comprehensive mathematical coding, the slightest distortion of the Quran's text or physical arrangement is immediately exposed.
              </p>
            </div>
            <h5 className='my-3 text-black'>Simple to Understand Impossible to Imitate</h5>
            <p>
              For the first time in history we have a scripture with built-in proof of divine authorship - a superhuman mathematical composition.
              <br/>
              Any reader of this book can easily verify the Quran's mathematical miracle. The word "God" (Allah) is written in bold capital letters throughout the text. The cumulative frequency of occurrence of the word "God" is noted at the bottom of each page in the left hand corner. The number in the right hand corner is the cumulative total of the numbers for verses containing the word "God." The last page of the text, Page 372, shows that the total occurrence of the word "God" is 2698, or 19x142. The total sum of verse numbers for all verses containing the word "God" is 118123, also a multiple of 19 (118123 = 19x6217).
              <br/>
              Nineteen is the common denominator throughout the Quran's mathematical system.
              <br/>
              This phenomenon alone suffices as incontrovertible proof that the Quran is God's message to the world. No human being(s) could have kept track of 2698 occurrences of the word "God," and the numbers of verses where they occur. This is especially impossible in view of (1) the age of ignorance during which the Quran was revealed, and (2) the fact that the suras and verses were widely separated in time and place of revelation. The chronological order of revelation was vastly different from the final format (Appendix 23). However, the Quran's mathematical system is not limited to the word "God;" it is extremely vast, extremely intricate, and totally comprehensive.
            </p>
            <h5 className='my-3 text-black'>The Simple Facts</h5>
            <p>
              Like the Quran itself, the Quran's mathematical coding ranges from the very simple, to the very complex. The Simple Facts are those observations that can be ascertained without using any tools. The complex facts require the assistance of a calculator or a computer. The following facts do not require any tools to be verified, but please remember they all refer to the original Arabic text:
            </p>
            <div className='table-con my-5 max-w-[506px] md:max-w-[1080px] mx-auto'>
              <h6 className='italic text-black text-center'>Table 1: List of the Quranic Initials and Their Suras</h6>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th style={{ width: '80px' }} className='border-0 p-4 text-white text-[12px] md:text-[14px] uppercase'>No.</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Detail</th>
                    <th className='border-0 p-4 text-white text-[12px] md:text-[14px] uppercase'>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The first verse (1:1), known as "Basmalah," consists of</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19 letters.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The Quran consists of 114 suras, which is </td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19 x 6.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The total number of verses in the Quran is 6346, or</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19 x 334.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>[6234 numbered verses & 112 un-numbered verses (Basmalahs) 6234+112 = 6346] Note that 6+3+4+6=</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The Basmalah occurs 114 times, despite its conspicuous absence from Sura 9 (it occurs twice in Sura 27) & 114=</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19 x 6.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>From the missing Basmalah of Sura 9 to the extra Basmalah of Sura 27, there are precisely</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19 suras.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>It follows that the total of the sura numbers from 9 to 27 (9+10+11+12+...+26+27) is 342, or</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19 x 18.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>This total (342) also equals the number of words between the two Basmalahs of Sura 27, and 342 =</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19 x 18.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The famous first revelation (96:1-5) consists of</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19 words.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>10.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>This 19-worded first revelation consists of 76 letters</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19 x 4.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>11.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Sura 96, first in the chronological sequence, consists of</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19 verses.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>12.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>This first chronological sura is placed atop the last</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19 suras.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>13.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Sura 96 consists of 304 Arabic letters, and 304 equals</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19 x 16.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>14.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The last revelation (Sura 110) consists of</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19 words.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>15.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The first verse of the last revelation (110:1) consists of</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19 letters.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>16.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>14 different Arabic letters, form 14 different sets of "Quranic Initials" (such as A.L.M. of 2:1), and prefix 29 suras. These numbers add up to 14+14+29=57=</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19 x 3.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>17.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The total of the 29 sura numbers where the Quranic Initials occur is 2+3+7+...+50+68 = 822, and 822+14 (14 sets of initials) equals 836, or</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19 x 44.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>18.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Between the first initialed sura (Sura 2) and the last initialed sura (Sura 68) there are 38 un-initialed suras</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19 x 2.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Between the first and last initialed sura there are of alternating "initialed" and "uninitialed" suras.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19 sets</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The Quran mentions 30 different numbers: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 19, 20, 30, 40, 50, 60, 70, 80, 99, 100, 200, 300, 1000, 2000, 3000, 5000, 50,000, & 100,000. The sum of these numbers is 162146, which equals</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19 x 8534.</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h6 className='text-center italic'>This is a condensed summary of the Simple Facts.</h6>
            <h5 className='my-3 text-black mt-10'>This is a condensed summary of the Simple Facts.</h5>

            <div className='grid grid-cols-1 md:grid-cols-2'>
              <div className='p-3 pe-10'>
                <p>
                  The Quran is characterized by a unique phenomenon never found in any other book; 29 suras are prefixed with 14 different sets of "Quranic Initials," consisting of one to five letters per set. Fourteen letters, half the Arabic alphabet, participate in these initials. The significance of the Quranic initials remained a divinely guarded secret for 14 centuries.
                  <br/>
                  The Quran states in 10:20 and 25:4-6 that its miracle, i.e., proof of divine authorship, was destined to remain secret for a specific predetermined interim:
                </p>
                <div className='p-3 border-[1px] border-primary rounded-[10px] my-4'>
                  <p>
                    They said, "Why hasn't a miracle come down to him from his Lord?" Say, "Only God knows the future. Therefore, wait, and I will wait along with you." [10:20]
                    <br/><br/>
                    Those who disbelieved said, "This is no more than a fabrication by him, with the help of other people." Indeed, they uttered a blasphemy; a falsehood. Others said, "Tales from the past that he wrote down; they were dictated to him day and night." Say, "This was sent down from the One who knows 'the secret' in the heavens and the earth." Surely, He is Forgiving, Most Merciful. [25:4-6]
                  </p>
                </div>
                <p>
                  The Quranic Initials constitute a major portion of the Quran's 19-based mathematical miracle.
                </p>
              </div>
              <div className='table-con'>
                <table className='w-full border-0'>
                  <thead className='bg-primary'>
                    <tr>
                      <th style={{ width: '10%' }} className='border-0 p-4 text-white text-[12px] md:text-[14px] uppercase'>No.</th>
                      <th style={{ width: '120px' }} className='border-0 p-4 text-white text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                      <th style={{ width: '35%' }} className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Surah Title</th>
                      <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Quranic Initials</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The Heifer</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.M.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The Amramites</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.M.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The Purgatory</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.M.S.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>10</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Jonah</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.R.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>11</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Hud</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.R.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>12</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Joseph</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.R.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>13</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Thunder</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.M.R.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>14</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Abraham</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.R.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>15</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Al-Hijr Valley</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.R.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>10.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Mary</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>K.H.Y.'A.S.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>11.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>T.H.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>T.H.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>12.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>26</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The Poets</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>T.S.M.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>13.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>27</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The Ant</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>T.S.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>14.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>28</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>History</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>T.S.M.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>15.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>29</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The Spider</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.M.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>16.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>30</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The Romans</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.M.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>17.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>31</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Luqmaan</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.M.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>18.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>32</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Prostration</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.M.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>36</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Y.S.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Y.S.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>38</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>S.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>S.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>21.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>40</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Forgiver</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H.M.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>22.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>41</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Elucidated</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H.M.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>23.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>42</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Consultation</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H.M.'A.S.Q.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>24.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>43</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Ornaments</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H.M.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>25.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>44</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Smoke</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H.M.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>26.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>45</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Kneeling</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H.M.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>27.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>46</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The Dunes</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H.M.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>28.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>50</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Q.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Q.</td>
                    </tr>
                    <tr className='border-[1px]'>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>29.</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>68</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The Pen</td>
                      <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>NuN</td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>

            <h5 className='my-3 text-black mt-10'>Historical Background.</h5>  

            <p>
              In 1968, I realized that the existing English translations of the Quran did not present the truthful message of God's Final Testament. For example, the two most popular translators, Yusuf Ali and Marmaduke Pickthall, could not overcome their corrupted religious traditions when it came to the Quran's great criterion in 39:45.
            </p>

            <div className='p-4 border border-primary rounded-[10px] my-5 mx-auto max-w-[850px]'>
              <p className='text-center'>
                When God ALONE is mentioned, the hearts of those who do not believe in the Hereafter shrink with aversion. But when others are mentioned beside Him, they rejoice. [39:45]
              </p>
            </div>

            <p>
              Yusuf Ali omitted the crucial word "ALONE" from his translation, and altered the rest of the verse by inserting the word "(gods)." Thus, he utterly destroyed this most important Quranic criterion. He translated 39:45 as follows:
            </p>
            <div className='p-4 border border-primary rounded-[10px] my-5 mx-auto max-w-[850px]'>
              <p className='text-center'>
                When God, the One and Only, is mentioned, the hearts of those who believe not in the Hereafter are filled with disgust and horror; but when (gods) other than He are mentioned, behold, they are filled with joy.[39:45]
                <br/>(according to A. Yusuf Ali)
              </p>
            </div>

            <p>
              The expression "When God, the One and Only, is mentioned," is not the same as saying, "When God alone is mentioned." One can mention "God, the One and Only," and also mention Muhammad or Jesus, and no one will be upset. But if "God ALONE is mentioned," you cannot mention anyone else, and a lot of people - those who idolize Muhammad or Jesus - will be upset. Thus, Yusuf Ali could not bring himself to present the truth of the Quran, if it exposed his corrupted belief.
              <br/>
              Marmaduke Pickthall translated "ALONE" correctly, but destroyed the criterion by inserting his personal belief in parentheses; he translated 39:45 as follows:
            </p>
            <div className='p-4 border border-primary rounded-[10px] my-5 mx-auto max-w-[850px]'>
              <p className='text-center'>
                And when Allah alone is mentioned, the hearts of those who believe not in the Hereafter are repelled, and when those (whom they worship) beside Him are mentioned, behold! they are glad. [39:45]
                <br/>(according to Marmaduke Pickthal)
              </p>
            </div>

            <p>
              When I saw the truth of God's word thus distorted, I decided to translate the Quran, at least for the benefit of my own children. Since I was a chemist by profession, and despite my extensive religious background - my father was a renowned Sufi leader in Egypt - I vowed to God that I would not move from one verse to the next unless I fully understood it.
              <br/>
              I purchased all the available books of Quranic translations and exegeses (Tafseer) I could find, placed them on a large table, and began my translation. The first sura, The Key, was completed in a few days. The first verse in Sura 2 is "A.L.M." The translation of this verse took four years, and coincided with the divine unveiling of "the secret," the great mathematical Miracle of the Quran.
              <br/>
              The books of Quranic exegeses unanimously agreed that "no one knows the meaning or significance of the Quranic Initials A.L.M., or any other initials." I decided to write the Quran into the computer, analyze the whole text, and see if there were any mathematical correlations among these Quranic initials.
              <br/>
              I used a time-share terminal, connected by telephone to a giant computer. To test my hypothesis, I decided to look at the single-lettered Quranic Initials - "Q" (Qaaf) of Suras 42 and 50, "S" (Saad) of Suras 7, 19, and 38, and "N" (Noon) of Sura 68. As detailed in my first book <span className='italic'>MIRACLE OF THE QURAN: SIGNIFICANCE OF THE MYSTERIOUS ALPHABETS</span> (Islamic Productions, 1973), many previous attempts to unravel the mystery had failed.
            </p>

            <h5 className='my-3 text-black mt-10'>The Quranic Initial "Q" (Qaaf)</h5>  
            <p>
              The computer data showed that the text of the only Q-initialed suras, 42 and 50, contained the same number of Q's, 57 and 57. That was the first hint that a deliberate mathematical system might exist in the Quran.
              <br/>
              Sura 50 is entitled "Q," prefixed with "Q," and the first verse reads, "Q, and the glorious Quran." This indicated that "Q" stands for "Quran," and the total number of Q's in the two Q-initialed suras represents the Quran's 114 suras (57+57 = 114 = 19x6). This idea was strengthened by the fact that "the Quran" occurs in the Quran 57 times.
              <br/>
              The Quran is described in Sura "Q" as "Majid" (glorious), and the Arabic word "Majid" has a gematrical value of 57: M (40)+J (3)+I (10)+D (4) = 57.
              <br/>
              Sura 42 consists of 53 verses, and 42+53 = 95 = 19x5.
              <br/>
              Sura 50 consists of 45 verses, and 50+45 = 95, same total as in Sura 42.
              <br/>
              By counting the letter "Q" in every "Verse 19" throughout the Quran, the total count comes to 76, 19x4. Here is a summary of the Q-related data:
            </p>

            <div className='table-con my-5 max-w-[506px] md:max-w-[1080px] mx-auto'>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th style={{ width: '80px' }} className='border-0 p-4 text-white text-[12px] md:text-[14px] uppercase'>No.</th>
                    <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Frequency of Occurence</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The frequency of occurrence of "Q" in Sura "Q" (No. 50) is 57, 19x3.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The letter "Q" occurs in the other Q-initialed sura (No. 42) exactly the same number of times, 57.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The total occurrence of the letter "Q" in the two Q- initialed suras is 114, which equals the number of suras in the Quran.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>"The Quran" is mentioned in the Quran 57 times.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The description of the Quran as "Majid" (Glorious) is correlated with the frequency of occurrence of the letter "Q" in each of the Q-initialed suras. The word "Majid" has a gematrical value of 57.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Sura 42 consists of 53 verses, and 42+53 is 95, or 19x5.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Sura 50 consists of 45 verses, and 50+45 is also 95, 19x5.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The number of Q's in all verses numbered "19" throughout the Quran is 76, 19x4.</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p>
              Glimpses of the Quran's mathematical composition began to emerge. For example, it was observed that the people who disbelieved in Lot are mentioned in 50:13 and occur in the Quran 13 times - 7:80; 11:70, 74, 89; 21:74; 22:43; 26:160; 27:54, 56; 29:28; 38:13; 50:13; and 54:33. Consistently, they are referred to as "Qawm," with the single exception of the Q-initialed Sura 50 where they are referred to as "Ikhwaan." Obviously, if the regular, Q-containing word "Qawm" were used, the count of the letter "Q" in Sura 50 would have become 58, and this whole phenomenon would have disappeared. With the recognized absolute accuracy of mathematics, the alteration of a single letter destroys the system.
              <br/><br/>
              Another relevant example is the reference to Mecca in 3:96 as "Becca"! This strange spelling of the renowned city has puzzled Islamic scholars for many centuries. Although Mecca is mentioned in the Quran properly spelled in 48:24, the letter "M" is substituted with a "B" in 3:96. It turns out that Sura 3 is an M-initialed sura, and the count of the letter "M" would have deviated from the Quran's code if "Mecca" was spelled correctly in 3:96.
            </p>

            <h5 className='my-3 text-black mt-10'>NuN (Noon)</h5>  
            <p>
              This initial is unique; it occurs in one sura, 68, and the name of the letter is spelled out as three letters - Noon Wow Noon - in the original text, and is therefore counted as two N's. The total count of this letter in the N-initialed sura is 133, 19x7. The fact that "N" is the last Quranic Initial (see Table 1) brings out a number of special observations. For example, the number of verses from the first Quranic Initial (A.L.M. of 2:1) to the last initial (N. of 68:1) is 5263, or 19x277.
              <br/><br/>
              The word "God" (Allah) occurs 2641 (19x139) times between the first initial and the last initial. Since the total occurrence of the word "God" is 2698, it follows that its occurrence outside the initials "A.L.M." of 2:1 on one side, and the initial "N" of 68:1 on the other side, is 57, 19x3. Tables 9 to 18 prove that the initial "NuN" must be spelled out to show two N's.
            </p>
            <h5 className='my-3 text-black mt-10'>S (Saad)</h5>  
            <p>
              This initial prefixes three suras, 7, 19, and 38, and the total occurrence of the letter "S" (Saad) in these three suras is 152, 19x8 (Table 2). It is noteworthy that in 7:69, the word "Bastatan" is written in some printings with a "Saad," instead of "Seen."
            </p>
            
            <h5 className='my-3 text-black text-center mt-10 italic'>Table 2: The Frequency of Occurrence of the Letter "S" in the Saad-initialed Suras</h5> 
            <div className='table-con my-5 max-w-[391px] mx-auto'>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th style={{ width: '40%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Sura</th>
                    <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Frequency of "S"</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>97</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>26</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>38</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>29</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-center' colSpan={2}>152 (19x8)</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p>
              This is an erroneous distortion that violates the Quran's code. By looking at the oldest available copy of the Quran, the Tashkent Copy, it was found that the word "Bastatan" is correctly written with a "Seen" (see photocopy below).
            </p>
            <div className='my-4 mx-auto max-w-[85%]'>
              <img src={tashkentImg} alt='Tashkent Quran Copy' className='w-full object-contain'/>
            </div>

            <h5 className='my-3 text-black mt-10'>Historical Note</h5>  
            <p>
              The momentous discovery that "19" is the Quran's common denominator became a reality in January 1974, coinciding with Zul-Hijjah 1393 A.H. The Quran was revealed in 13 B.H. (Before Hijrah). This makes the number of years from the revelation of the Quran to the revelation of its miracle 1393 + 13 = 1406 = 19x74. As noted above, the unveiling of the Miracle took place in January 1974. The correlation between 19x74 lunar years and 1974 solar years could not escape notice. This is especially uncanny in view of the fact that "19" is mentioned in Sura 74.
            </p>

            <h5 className='my-3 text-black mt-10'>Y. S. (Ya Seen)</h5>  
            <p>
              These two letters prefix Sura 36. The letter "Y" occurs in this sura 237 times, while the letter "S" (Seen) occurs 48 times. The total of both letters is 285, 19x15.
              <br/><br/>
              It is noteworthy that the letter "Y" is written in the Quran in two forms; one is obvious and the other is subtle. The subtle form of the letter may
              be confusing to those who are not thoroughly familiar with the Arabic language. A good example is the word <em>"Araany</em>
              <img src={arranyImg} alt='Arrany' className='h-[25px] inline mx-1'/>" which is mentioned twice in 12:36. The letter "Y" is used twice in this word, the first "Y" is subtle and the second is obvious. Sura 36 does not contain a single "Y" of the subtle type. This is a remarkable phenomenon, and one that does not normally occur in a long sura like Sura 36. In my book QURAN: VISUAL PRESENTATION OF THE MIRACLE (Islamic Productions, 1982) every "Y" and "S" in Sura 36 is marked with a star.
            </p>

            <h5 className='my-3 text-black mt-10'>H.M. (Ha Mim)</h5>  
            <p>
              Seven suras are prefixed with the letters "H  
              <img src={haImg} alt='Arrany' className='h-[25px] inline mx-1'/>" and "M  
              <img src={mimImg} alt='Arrany' className='h-[25px] inline mx-1'/>;" Suras 40 through 46. 
              The total occurrence of these two letters in the seven H.M.-initialed suras is 2147, or 19x113. The detailed data are shown in Table 3. Naturally, the alteration of a single letter "H" or "M" in any of the seven H.M.-initialed suras would have destroyed this intricate phenomenon.
            </p>

            <h5 className='my-3 text-black text-center mt-10 italic'>Table 3: Occurence of the Letters "H" and "M" in the Seven H.M.-Initialed Suras</h5>
            <div className='table-con my-5 max-w-[506px] mx-auto'>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th style={{ width: '30%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                    <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase' colSpan={3}>Frequency of Occurence</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th style={{ width: '30%' }} className='border-0'></th>
                    <th className='border-0 p-4 text-black text-center text-[12px] md:text-[14px] uppercase'>"H"</th>
                    <th className='border-0 p-4 text-black text-center text-[12px] md:text-[14px] uppercase'>"M"</th>
                    <th className='border-0 p-4 text-black text-center text-[12px] md:text-[14px] uppercase'>"H+M"</th>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>40</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>64</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>380</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>444</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>41</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>48</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>276</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>324</td>
                  </tr>
                 <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>42</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>53</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>300</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>353</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>43</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>44</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>324</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>368</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>44</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>16</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>150</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>166</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>45</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>31</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>200</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>231</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>46</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>36</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>225</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>261</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>292</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1855</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2147</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-end' colSpan={4}>	(19x113)</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h5 className='my-3 text-black mt-10'>'A.S.Q. ('Ayn Seen Qaf)</h5>
            <p>
              These initials constitute Verse 2 of Sura 42, and the total occurrence of these letters in this sura is 209, or 19x11. The letter " `A" (`Ayn) occurs 98 times, the letter "S" (Seen) occurs 54 times, and the letter "Q" (Qaf) occurs 57 times.
            </p>

            <h5 className='my-3 text-black mt-10'>A.L.M. (Alef Laam Mim)</h5>
            <p>
              The letters "A," "L," and "M" are the most frequently used letters in the Arabic language, and in the same order as we see in the Quranic Initials - "A," then "L," then "M." These letters prefix six suras - 2, 3, 29, 30, 31, and 32 - and the total occurrence of the three letters in each of the six suras is a multiple of 19 [9899 (19x521), 5662 (19x 298), 1672 (19x88), 1254 (19x66), 817 (19x43), and 570 (19x30), respectively]. Thus, the total occurrence of the three letters in the six suras is 19874 (19x 1046), and the alteration of one of these letters destroys this phenomenon.
            </p>
            <h5 className='my-3 text-black text-center mt-10 italic'>Table 4: Occurence of the Letters "A," "L," and "M" in the A.L.M.-Initialed Suras.</h5>
            <div className='table-con my-5 max-w-[506px] mx-auto'>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th style={{ width: '25%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                    <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase' colSpan={4}>Frequency of Occurence</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='border-0'></td>
                    <td className='border-0 p-4 text-black text-center text-[12px] md:text-[14px] uppercase'>"A"</td>
                    <td className='border-0 p-4 text-black text-center text-[12px] md:text-[14px] uppercase'>"L"</td>
                    <td className='border-0 p-4 text-black text-center text-[12px] md:text-[14px] uppercase'>"R"</td>
                    <td className='border-0 p-4 text-black text-center text-[12px] md:text-[14px] uppercase'>Total</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>10</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1319</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>913</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>257</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2489 (19x131)</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>12</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1306</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>812</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>257</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2375 (19x125)</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>14</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>585</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>452</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>160</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1197 (19x63)</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>15</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>493</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>323</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>96</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>912 (19x48)</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5073</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3294</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1095</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9462 (19x498)</td>
                  </tr>
                 
                </tbody>
              </table>
            </div>


            <h5 className='my-3 text-black mt-10'>A.L.R. (Alef Laam Ra)</h5>
            <p>
              These initials are found in Suras 10, 11, 12, 14, and 15. The total occurrences of these letters in these suras are 2489 (19x131), 2489 (19x131), 2375 (19x 125), 1197 (19x63), and 912 (19x48), respectively (Table 5).
            </p>

            <h5 className='my-3 text-black mt-10'>A.L.M.S. (Alef Laam Mim Saad)</h5>
            <p>
              Only one sura is prefixed with these initials, Sura 7, and the letter "A" occurs in this sura 2529 times, "L" occurs 1530 times, "M" occurs 1164 times, and "S" (Saad) occurs 97 times. Thus, the total occurrence of the four letters in this sura is 2529+1530+1164+97 = 5320 = 19x280.
              <br/><br/>
              An important observation here is the interlocking relationship involving the letter "S" (Saad). This initial occurs also in Suras 19 and 38. While complementing its sister letters in Sura 7 to give a total that is divisible by 19, the frequency of this letter also complements its sister letters in Suras 19 and 38 to give a multiple of 19 (see Page 380).
              <br/><br/>
              Additionally, the Quranic Initial "S" (Saad) interacts with the Quranic Initials "K.H.Y. `A." (Kaaf Haa Ya `Ayn) in Sura 19 to give another total that is also a multiple of 19 (see Page 383). This interlocking relationship - which is not unique to the initial "S" (Saad) - contributes to the intricacy of the Quran's numerical code.
            </p>

            <h5 className='my-3 text-black mt-10'>K.H.Y.`A.S. (Kaaf Ha Ya `Ayn Saad)</h5>
            <p>
              This is the longest set of initials, consisting of five letters, and it occurs in one sura, Sura 19. The letter "K" in Sura 19 occurs 137 times, "H" occurs 175 times, "Y" occurs 343 times, " `A" occurs 117 times, and "S" (Saad) occurs 26 times. Thus, the total occurrence of the five letters is 137+175+343+117+26 = 798 = 19x42.
            </p>

            <h5 className='my-3 text-black mt-10'>H., T.H. (Ta Ha), T.S. (Ta Seen), & T.S.M. (Ta Seen Mim)</h5>
            <p>
              An intricate interlocking relationship links these overlapping Quranic Initials to produce a total that is also a multiple of 19. The initial "H." is found in Suras 19 and 20. The initials "T.H." prefix Sura 20. The initials "T.S." are found in Sura 27, while the initials "T.S.M." prefix its surrounding Suras 26 & 28.
              <br/><br/>
              It should be noted at this time that the longer, more complex, interlocking and overlapping initials are found in the suras where uncommonly powerful miracles are narrated. For example, the virgin birth of Jesus is given in Sura 19, which is prefixed with the longest set of initials, K.H.Y.`A.S.
              <br/><br/>
              The interlocking initials "H.," "T.H.," "T.S.," and "T.S.M." prefix suras describing the miracles of Moses, Jesus, and the uncommon occurrences surrounding Solomon and his jinns. God thus provides stronger evidence to support stronger miracles. The frequencies of occurrence of these initials are presented in Table 6.
            </p>

            <h5 className='my-3 text-black text-center mt-10 italic'>Table 6: Occurence of the Quranic Initials "H.," "T.H.," "T.S. ", and "T.S.M." in Their Suras</h5>
            <div className='table-con my-5 max-w-[506px] mx-auto'>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th style={{ width: '25%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                    <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase' colSpan={4}>Frequency of Occurence</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='border-0'></td>
                    <td className='border-0 p-4 text-black text-center text-[12px] md:text-[14px] uppercase'>"H"</td>
                    <td className='border-0 p-4 text-black text-center text-[12px] md:text-[14px] uppercase'>"T"</td>
                    <td className='border-0 p-4 text-black text-center text-[12px] md:text-[14px] uppercase'>"S</td>
                    <td className='border-0 p-4 text-black text-center text-[12px] md:text-[14px] uppercase'>"M"</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>175</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>251</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>28</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>26</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>33</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>94</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>484</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>27</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>27</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>94</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>28</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>102</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>460</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>426</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>107</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>290</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>944</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-end' colSpan={5}>426+107+290+944 = 1767 = (19x93)</td>
                  </tr>
                  
                 
                </tbody>
              </table>
            </div>

            <h5 className='my-3 text-black mt-10'>What Is A "Gematrical Value"?</h5>
            <p>
              When the Quran was revealed, 14 centuries ago, the numbers known today did not exist. A universal system was used where the letters of the Arabic, Hebrew, Aramaic, and Greek alphabets were used as numer-als. The number assigned to each letter is its "Gematrical Value." The numerical values of the Arabic alphabet are shown in Table 7.
            </p>
            <div className='my-4 mx-auto max-w-[85%]'>
              <img src={gvImg} alt='Tashkent Quran Copy' className='w-full object-contain'/>
            </div>

            <h5 className='my-3 text-black mt-10'>Other Mathematical Properties of the Initialed Suras</h5>
            <p>
              Fourteen Arabic letters, half the Arabic alphabet, participate in the formation of 14 different sets of Qur-anic Initials. By adding the gematrical value of each one of these letters, plus the number of suras which are prefixed with Quranic Initials (29), we obtain a total of 722, or 19x19x2.
              <br/><br/>
              Additionally, if we add the total gematrical value of all 14 initials, plus the number of the first sura where the initial occurs, we get a grand total of 988, 19x52. Table 8 presents these data
            </p>
            <h5 className='my-3 text-black text-center mt-10 italic'>Table 8: The 14 Letters Used in Forming Quranic Initials</h5>
            <div className='table-con my-5 max-w-[506px] mx-auto'>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th style={{ width: '35%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Letter</th>
                    <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Value</th>
                    <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>First Sura</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A (Alef)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>L (Laam)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>30</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>M (Mim)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>40</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>S (Saad)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>90</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>R (Ra)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>200</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>10</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>K (Kaf)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H (Ha)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Y (Ya)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>10</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>'A ('Ayn)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>70</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>T (Ta)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>S (Seen)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>60</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>26</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H (Ha)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>40</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Q (Qaf)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>100</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>42</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>N (Noon)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>50</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>68</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>693</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>295</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-center' colSpan={3}>
                    693 + 295 = 988 = 19x52
                    </td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-center' colSpan={3}>
                    also 693 + 29 (suras) = 722 = 19x19x2
                    </td>
                  </tr>
                 
                </tbody>
              </table>
            </div>

            <p>
              If we add the number of occurrences of each of the 14 letters listed in Table 8 as an initial, plus the numbers of the suras where it occurs as an initial, the Grand Total comes to 2033, 19x107. See Table 9.
            </p>

            <h5 className='my-3 text-black text-center mt-10 italic'>Table 9: Mathematically Structured Distribution of the Quranic Initials</h5>
            <div className='table-con my-5 max-w-[1080px] mx-auto'>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th style={{ width: '15%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Initial</th>
                    <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>	Number of Occurrence</th>
                    <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Suras Where It Occurs</th>
                    <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A (Alef)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>13</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>[+ 2 + 3 + 7 + 10 + 11 + 12 + 13 + 14 + 15 + 29 + 30 + 31 + 32]</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>222</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>L (Laam)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>13</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>[+ 2 + 3 + 7 + 10 + 11 + 12 + 13 + 14 + 15 + 29 + 30 + 31 + 32]</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>222</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>M (Mim)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>17</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>[+ 2 + 3 + 7 + 13 + 26 + 28 + 29 + 30 + 31 + 32 + 40 + 41 + 42 + 43 + 44 + 45 + 46]</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>519</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>M (Mim)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>17</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>[+ 2 + 3 + 7 + 13 + 26 + 28 + 29 + 30 + 31 + 32 + 40 + 41 + 42 + 43 + 44 + 45 + 46]</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>519</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>S (Saad)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	+ 7 + 19 + 38</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>67</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>R (Ra)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+ 10 + 11 + 12 + 13 + 14 + 15</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>81</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>K (Kaf)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+ 19</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H (Ha)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+ 19 + 20</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>41</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Y (Ya)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+ 19 + 36</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>57</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>'A ('Ayn)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+ 19 + 42</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>63</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>T (Ta)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+ 20 + 26 + 27 + 28</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>105</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>S (Seen)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+ 26 + 27 + 28 + 36 + 42</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>164</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H (HHa)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+ 40 + 41 + 42 + 43 + 44 + 45 + 46</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>308</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Q (Qaf)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+ 42 + 50</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>94</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>N (Noon)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+ 68</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>70</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                    <td className='p-4 text-black font-semibold text-[12px] md:text-[14px] text-center'>79</td>
                    <td className='p-4 text-black font-semibold text-[12px] md:text-[14px] text-start'>1954</td>
                    <td className='p-4 text-black font-semibold text-[12px] md:text-[14px] text-start'>2033</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-end' colSpan={4}>	(19x107)</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>Table 10 presents the total frequency of Quranic Initials, plus the total gematrical value of these letters in the whole sura. The Grand Total for all initialed suras is 1089479. This number, in excess of one million, is a multiple of 19 (1089479 = 19 x 57341). The slightest alteration or distortion destroys the system.</p>

            <p className='italic'>
            Note: The total gematrical value of the Quranic Initials in a given sura equals the gematrical value of each initial multiplied by the frequency of occurrence of that initial in the sura.
            </p>

            <h5 className='my-3 text-black mt-10'>Major Parameters of the Quranic Initials (Suras, Verses, Frequency, First Sura, & Last Sura)</h5>
            <p>
              Table 11 shows that the sum of numbers of suras and verses where the Quranic Initials are found, plus the initial's frequency of occurrence in that sura, plus the number of the first sura where the initials occur, plus the number of the last sura where the initials occur, produces a total that equals 44232, or 19x2348. Thus, the distribution of the Quranic Initials in the initialed suras is so intricate that their counts and their placement within suras are intertwined to give a grand total that is a multiple of 19.
            </p>
            <h5 className='my-3 text-black text-center mt-10 italic'>Table 10: Total Gematrical Values of All Quranic Initials In Their Suras</h5>
            <div className='table-con my-5 max-w-[1080px] mx-auto'>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th style={{ width: '85px' }} className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sura</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Initials</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Frequency of Initials</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Tot.G.Val. in Sura</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.M.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9899</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>188362</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.M.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5662</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>109241</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.M.S</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5320</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>103719</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>10</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.R.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2489</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>80109</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>11</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.R.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2489</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>90190</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>12</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.R.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2375</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>77066</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>13</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.M.R.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1482</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>52805</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>14</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.R.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1197</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>46145</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>15</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.R.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>912</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>29383</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>K.H.Y.'A.S.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>798</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>17575</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>T.H.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>279</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1507</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>26</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>T.S.M.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>611</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>25297</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>27</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>T.S.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>121</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5883</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>28</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>T.S.M.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>581</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>24691</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>29</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.M.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1672</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>31154</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>30</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.M.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1254</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>25014</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>31</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.M.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>817</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>16177</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>32</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A.L.M.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>570</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>11227</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>36</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Y.S.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>285</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5250</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>38</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>S.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>29</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2610</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>40</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H.M.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>444</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>15712</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>41</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H.M.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>324</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>11424</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>42</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H.M.-'A.S.Q.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>562</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>28224</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>43</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H.M.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>368</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>13312</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>44</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H.M.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>166</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6128</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>45</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H.M.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>231</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8248</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>46</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H.M.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>261</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9288</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>50</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Q</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>57</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5700</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>68</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>N,N</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>133</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6650</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>41388</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	1048091</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-center' colSpan={4}>41388 + 1048091 = 1089479 (19 x 57341)</td>
                  </tr>
                </tbody>

              </table>
            </div>

            <p>
              It is noteworthy that the initial "N" must be counted as two N's. This reflects the fact that the original Quranic text spells out this initial with 2 N's.
            </p>

            <h5 className='my-3 text-black text-center mt-10 italic'>Table 11: Parameters of the 14 Individual Quranic Initials</h5>
            <div className='table-con my-5 max-w-[1080px] mx-auto'>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th style={{ width: '12%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Initial</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sura , Verse, & (Frequency) of Initial in Each Sura</th>
                    <th style={{ width: '12%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>First Sura</th>
                    <th style={{ width: '12%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Last Sura</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A (Alef)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>
                      2:1 (4502), 3:1 (2521), 7:1 (2529), 10:1 (1319) 11:1 (1370), 12:1 (1306), 13:1 (605), 14:1 (585), 15:1 (493), 29:1 (774), 30:1 (544), 31:1 (347), 32:1 (257)
                    </td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>32</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>L (Laam)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>
                      2:1 (3202), 3:1 (1892), 7:1 (1530), 10:1 (913), 11:1 (794), 12:1 (812), 13:1 (480), 14:1 (452), 15:1 (323), 29:1 (554), 30:1 (393), 31:1 (297), 32:1 (155)
                    </td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>32</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>M (Mim)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>
                      2:1 (2195), 3:1 (1249), 7:1 (1164), 13:1 (260) 26:1 (484), 28:1 (460), 29:1 (344), 30:1 (317), 31:1 (173), 32:1 (158), 40:1 (380), 41:1 (276), 42:1 (300), 43:1 (324), 44:1 (150), 45:1 (200), 46:1(225)
                    </td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>46</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>S (Saad)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>
                      7:1 (97), 19:1 (26), 38:1 (29)
                    </td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>38</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>R (Ra)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>
                      10:1 (257), 11:1 (325), 12:1 (257), 13:1 (137), 14:1 (160), 15:1 (96)
                    </td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>10</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>15</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>K (Kaf)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>
                      19:1 (137)
                    </td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H (Ha)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>
                      19:1 (175), 20:1 (251)
                    </td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Y (Ya)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>
                      19:1 (343), 36:1 (237)
                    </td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>36</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>'A ('Ayn)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>
                      19:1 (117), 42:2 (98)
                    </td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>42</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>T (Ta)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>
                      20:1 (28), 26:1 (33), 27:1 (27), 28:1 (19)
                    </td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>28</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>S (Seen)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>
                      26:1 (94), 27:1 (94), 28:1 (102), 36:1 (48), 42:2 (54)
                    </td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>26</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>42</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H (HHa)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>
                      40:1 (64), 41:1 (48), 42:1 (53), 43:1 (44) 44:1 (16), 45:1 (31), 46:1 (36)
                    </td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>40</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>46</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>Q (Qaf)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>42:2 (57), 50:1 (57)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>42</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>50</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>N (NuN)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>68:1 (133)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>68</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>68</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>N (NuN)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>68:1 (133)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>68</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>68</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>43423</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>295</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>514</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-center' colSpan={4}>
                      Grand Total = 43423+295+514 = 44232 = 19x2328.
                    </td>
                  </tr>
                </tbody>

              </table>
            </div>

            <p>
              A special mathematical coding authenticates the number of verses where the Quranic Initials themselves are found. As detailed in Table 11, all Quranic Initials occur in Verse 1, except in Sura 42 (initials in Verses 1 and 2). This fact is supported by the remarkable mathematical phenomenon detailed in Table 12. If we multiply the first two columns of Table 12, instead of adding, we still end up with a Total that is divisible by 19 (see Table 13).
            </p>

            <div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>
              <div className=''>
                <h5 className='my-3 text-black text-center mt-10 italic'>Table 12: Mathematical Coding of the Number of Verses with Initials</h5>
                <div className='table-con my-5 max-w-[1080px] mx-auto'>
                  <table className='w-full border-0'>
                    <thead className='bg-primary'>
                      <tr>
                        <th style={{ width: '20%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                        <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>No. of Initials</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Initialed Verses</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>10</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>11</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>12</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>13</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>14</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>15</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>26</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>27</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>28</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>29</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>30</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>31</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>32</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                       </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>36</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>38</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>40</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>41</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>42</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>43</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>44</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>45</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>46</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>50</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>68</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>822</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>79</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>30</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-center' colSpan={3}>
                          822 + 79 + 30 = 931 (19x49)
                          </td> 
                      </tr>
                    </tbody>


                  </table>
                </div>
              </div>
              <div className=''>
              <h5 className='my-3 text-black text-center mt-10 italic'>Table 13: Multiplying the First Two Columns of Table 12, Instead of Adding</h5>
                <div className='table-con my-5 max-w-[1080px] mx-auto'>
                  <table className='w-full border-0'>
                    <thead className='bg-primary'>
                      <tr>
                        <th style={{ width: '20%' }} className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                        <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'></th>
                        <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>No. of Initials</th>
                        <th className='border-0 p-4 text-white text-center text-[12px] md:text-[14px] uppercase'>No. Of Init'ld Verses</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>x</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>x</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>x</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>42</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>x</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>50</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>x</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>68</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>x</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2022</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>30</td>
                      </tr>
                      
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-center' colSpan={3}>
                          2022 + 30 = 2052 (19x108)
                        </td> 
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <p>
              Obviously, it is crucial to have two different initialed verses in Sura 42 in order to conform with the Quran's mathematical code. The fact that Verse 1 of Sura 42 consists of the two Quranic Initials "H.M." and the second verse consists of the three Initials " `A.S.Q." has perplexed Muslim scholars and orientalists for 14 centuries.
              <br/><br/>
              By the end of this Appendix, the read-er will see that every element of the Quran is mathematically authenticated. The elements we are dealing with now are "the number of Quranic Initials in each initialed sura" and "the number of verses that contain Quranic Initials." Tables 11 through 13 have dealt with these two elements.
              <br/><br/>
              Additional mathematical authentication is shown in Tables 14 and 15. In Table 14, we have the numbers of all initialed suras added to the number of verses in each sura, plus the number of verses containing initials, plus the gematrical values of those initials. The Grand Total is 7030, or 19x370.
            </p>

            <h5 className='my-3 text-black text-center mt-10 italic'>Table 14: Mathematical Properties of the Initialed Suras</h5>
            <div className='table-con my-5 max-w-[1080px] mx-auto'>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Number of Verses</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Number Of Initialed Verses</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Gematrical Value of the Initials</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>286</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>71</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>360</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>200</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>71</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>275</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>206</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>161</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>375</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>10</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>109</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>231</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>351</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>11</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>123</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>231</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>366</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>12</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>111</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>231</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>355</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>13</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>43</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>271</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>328</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>14</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>52</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>231</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>298</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>15</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>99</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>231</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>346</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>98</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>195</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>313</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>135</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>14</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>170</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>26</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>227</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>109</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>363</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>27</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>93</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>69</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>190</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>28</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>88</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>109</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>226</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>29</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>69</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>71</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>170</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>30</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>60</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>71</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>162</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>31</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>34</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>71</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>137</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>32</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>30</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>71</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>134</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>36</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>83</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>70</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>190</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>38</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>88</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>90</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>217</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>40</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>85</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>48</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>174</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>41</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>54</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>48</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>144</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>42</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>53</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>278</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>375</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>43</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>89</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>48</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>181</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>44</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>59</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>48</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>152</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>45</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>37</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>48</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>131</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>46</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>35</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>48</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>130</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>50</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>45</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>100</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>196</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>68</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>52</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>50 + 50</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>221</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>822</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+ 2743</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+ 30</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+ 3435</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>= 7030</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-end' colSpan={5}>
                      (19x370)
                    </td> 
                  </tr>
                </tbody>
              </table>
            </div>

            <p>
              Remarkably, if we multiply the first two columns of Table 14, instead of adding them, we still get a Grand Total that is divisible by 19 (Table 15).
              <br/><br/>
              The number of verses per sura, and the numbers assigned to each verse are among the basic elements of the Quran. Not only are these elements authenticated mathematically, but both initialed and un-initialed suras are independently coded. Since we are now dealing with the initialed suras, Table 16 presents the numbers assigned to these suras, added to the numbers of verses in each sura, plus the sum of verse numbers (1+2+3+ ... + n). The Grand total is 190133, or 19x10007.
            </p>

            <h5 className='my-3 text-black text-center mt-10 italic'>Table 15: Multiplying the First 2 Columns of Table 14, Instead of Adding Them</h5>
            <div className='table-con my-5 max-w-[1080px] mx-auto'>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Number of Verses</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Number Of Initialed Verses</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Gematrical Value of the Initials</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	x	286	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+	1	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+	71</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	=	644</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>x	200	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+	1	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+	71</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	=	672</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	x	206</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	+	1	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+	161</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	=	1604</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>50	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>x	45</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	+	1	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+	100	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>=	2351</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>681	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>x	52</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	+	1	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+	(50+50)	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>=	3637</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>60071		</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+	30	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>+	3435</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	=	63536</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-end' colSpan={5}>
                      (19x3344)
                    </td> 
                  </tr>
                </tbody>
              </table>
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>
              <div>
                <p>
                  By adding the number of every sura to the number of the next sura, and accumulating the sums of sura numbers as we continue this process to the end of the Quran, we will have a value that corresponds to each sura. Thus, Sura 1 will have a corresponding value of 1, Sura 2 will have a value of 1+2=3, Sura 3 will have a value of 3+3=6, Sura 4 will have a value of 6+4 = 10, and so on to the end of the Quran. The total values for the initialed and the un-initialed suras are independently divisible by 19. The values for the initialed suras are shown in Table 17.
                </p>
                <h5 className='my-3 text-black text-center mt-10 italic'>Table 16: Mathematical Structuring of the Verses of Initialed Suras</h5>
                <div className='table-con my-5 max-w-[1080px] mx-auto'>
                  <table className='w-full border-0'>
                    <thead className='bg-primary'>
                      <tr>
                        <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                        <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>No.of Verses</th>
                        <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sum of Verse #s</th>
                        <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>286</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>41041</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>41329</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3	</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>200	</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20100	</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20303</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>206</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>21321</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>21534</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>50	</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>45	</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1035	</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1130</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>68</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>52</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1378</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1498</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>822</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2743</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>186568</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>190133</td>
                      </tr>
                      
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-end' colSpan={4}>
                          (19x10007)
                        </td> 
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
              <h5 className='my-3 text-black text-center mt-10 italic'>Table 17: Values Obtained by Successive Addition of Sura Numbers.</h5>
                <div className='table-con my-5 max-w-[500px] mx-auto px-[20px]'>
                  <table className='w-full border-0'>
                    <thead className='bg-primary'>
                      <tr>
                        <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                        <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Calculated Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>28</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>10</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>55</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>11</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>66</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>12</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>78</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>12</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>91</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>14</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>105</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>15</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>120</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>190</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>210</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>44</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>990</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>45</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1035</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>46</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1081</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>50</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1275</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>68</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2346</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>15675</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                        <td className='p-4 text-black text-[12px] md:text-[14px] text-start font-bold'>(19x825)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <p>
              The values calculated for the un-initialed suras add up to a total of 237785, which is also a multiple of 19 (237785 = 19x12515).
            </p>

            <h5 className='my-3 text-black mt-10'>Mathematical Coding of Special Words The Word "God" (Allah)</h5>
            <ul className='list-none'>
              <li>
                [1] As shown earlier the word "God" occurs in the Quran 2698 times, 19x142.
              </li>
              <li>
                [2] The numbers of verses where the word "God" occurs add up to 118123, also a multiple of 19 (118123 = 19x6217).
              </li>
            </ul>
            <p>
              These simple phenomena gave us many difficulties while simply counting the word "God." We were a group of workers, equipped with computers, and all of us college graduates. Yet, we made several errors in counting, calculating, or simply writing the counts of the word "God." Those who still claim that Muhammad was the author of the Quran are totally illogical; he never went to college, and he did not have a computer.
            </p>
            <ul className='list-none'>
              <li>
                [3] From the first Quranic Initials (A.L.M. 2:1) to the last initial (N. 68:1), there are 2641, 19 x 139, occurrences of the word "God."
              </li>
              <li>
                [4] The word "God" occurs 57 times in the section outside the Initials (Table 18).
              </li>
              <li>
                [5] By adding the numbers of the suras and verses where these 57 occurrences of the word "God" are found, we get a total of 2432, or 19x128. See Table 18.
              </li>
              <li>
                [6] The word "God" occurs in 85 suras. If we add the number of each sura to the number of verses between the first and last occurrences of the word "God," both verses inclusive, the Grand Total comes to 8170 or 19 x 430. An abbreviated representation of the data is shown in Table 19.
              </li>
            </ul>

            <h5 className='my-3 text-black text-center mt-10 italic'>Table 15: Multiplying the First 2 Columns of Table 14, Instead of Adding Them</h5>
            <div className='table-con my-5 max-w-[600px] mx-auto'>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Number of Sura</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>No.of Verses</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Times Occurs</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1,2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>69</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>33</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>70</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>71</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3,4,13,15,17,19,25</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>72</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4,5,7,12,18,19,22,23</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>10</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>73</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>74</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>31,56</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>76</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6,9,11,30</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>79</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>25</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>81</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>29</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>82</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>84</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>23</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>85</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8,9,20</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>87</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>88</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>24</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>91</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>13</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>95</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>96</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>14</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>98</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2,5,8</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>104</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>110</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1,2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>112</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1,2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1798</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>634</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>57</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-end' colSpan={3}>
                      (19x3)
                    </td> 
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='p-4 text-black text-[12px] md:text-[14px] text-start' colSpan={3}>
                      Sum of numbers of the suras & verses = 1798 + 634 = 2432 = 19 x 128
                    </td> 
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='p-4 text-black text-[12px] md:text-[14px] text-start' colSpan={3}>
                      Total occurence of the word "God" outside the initialed section = 57 (19 x 3).
                    </td> 
                  </tr>
                </tbody>
              </table>
            </div>

            <h5 className='my-3 text-black text-center mt-10 italic'>Table 15: Multiplying the First 2 Columns of Table 14, Instead of Adding Them</h5>
            <div className='table-con my-5 max-w-screen-md mx-auto'>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'></th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sura No</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>First Verse</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Last Verse</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Verses 1st to Last</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>286</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>280</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>200</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>199</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>84.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>110</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>85.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>112</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                    <td className='p-4 text-black text-[12px] md:text-[14px] text-start font-medium'>3910</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                    <td className='p-4 text-black text-[12px] md:text-[14px] text-start font-medium'>4260</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-center' colSpan={5}>
                      3910 + 4260 = 8170 = 19x430
                    </td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-center' colSpan={5}>
                      These mathematical properties cover all occurrences of the word "God."
                    </td>
                  </tr>
                  
                </tbody>
              </table>
            </div>

            <ul className='list-none flex gap-10'>
              <li>
                [7] The Quran's dominant message is that there is only "One God." The word "One," in Arabic "Wahed" occurs in the Quran 25 times. Six of these occurrences refer to other than God (one kind of food, one door, etc.). The other 19 occurrences refer to God. These data are found in the classic reference INDEX TO THE WORDS OF QURAN.
              </li>
              <li>
                The crucial importance of the word "ONE" as the Quran's basic message is manifested in the fact that the Quran's common denominator, 19, happens to be the gematrical value of the word "ONE."
              </li>
            </ul>

            <h5 className='my-3 text-black mt-10'>WHY 19!</h5>
            <p>
              As pointed out later in this Appendix, all God's scriptures, not only the Quran, were mathematically coded with the number "19." Even the universe at large bears this divine mark. The number 19 can be looked upon as the Almighty Creator's signature on everything He created (see Appendix 38). The number "19" possesses unique mathematical properties beyond the scope of this Appendix. For example:
            </p>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>
              <div className='col-span-2'>
                <ul className='list-none'>
                  <li>
                  [1] It is a prime number.
                  </li>
                  <li>
                  [2] It encompasses the first numeral (1) and the last numeral (9), as if to proclaim God's attribute in 57:3 as the "Alpha and the Omega."
                  </li>
                  <li>
                  [3] It looks the same in all languages of the world. Both components, 1 and 9, are the only numerals that look the same in all languages.
                  </li>
                  <li>
                  [4] It possesses many peculiar mathematical properties. For example, 19 is the sum of the first powers of 9 and 10, and the difference between the second powers of 9 and 10.
                  </li>
                </ul>
                <p>
                  We now understand that the uni-versal coding of God's creations with the number 19 rests in the fact that it is the gematrical value of the word "ONE" in all the scriptural languages - Aramaic, Hebrew, and Arabic.
                  <br/><br/>
                  The number 19, therefore, pro-claims the First Commandment in all the scriptures: that there is only ONE God.
                </p>
              </div>
              <div className='col-span-1'>
                <div className='p-2 border-[2px] border-primary'>
                  <ul className='list-none'>
                    <li>The Lord our God is ONE!</li>
                    <li>Therefore, you shall worship</li>
                    <li>the Lord your God</li>
                    <li>with all your heart,</li>
                    <li>with all your soul,</li>
                    <li>with all your mind,</li>
                    <li>and with all your strength.</li>
                  </ul>
                  <p className='text-end mt-2'>
                    [Deuteronomy 6:4-5]<br/>
                    [Mark 12:29]<br/>
                    [Quran 12:163, 17:22-23]
                  </p>
                </div>
                <h5 className='my-3 text-black text-center mt-10 italic'>Table 20: Why "19!"</h5>
                <div className='table-con my-5 max-w-screen-md mx-auto'>
                  <table className='w-full border-0'>
                    <thead className='bg-primary'>
                      <tr>
                        <th className='border-0 py-2 px-4 text-white text-center text-[12px] md:text-[14px] uppercase' colSpan={2}>Letter</th>
                        <th className='border-0 py-2 px-4 text-white text-start text-[12px] md:text-[14px] uppercase'></th>
                      </tr>
                      <tr>
                        <th className='border-0 py-2 px-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Hebrew</th>
                        <th className='border-0 py-2 px-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Arabic</th>
                        <th className='border-0 py-2 px-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>V</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>W</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>A</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>H</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>D</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>D</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-end' colSpan={3}>19</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <p>
              As shown in Table 7, the Aramaic, Hebrew, and Arabic alphabets used to double as numerals in accordance with a universally established system. The Hebrew word for "ONE" is "VAHD" (pronounced V-AHAD). In Arabic, the word for "ONE" is "WAHD" (pronounced WAAHED). See Table 20.
            </p>

            <h5 className='my-3 text-black mt-10'>The Word "Quran"</h5>
            <p>
              The word "Quran" occurs in the Quran 58 times, with one of them, in 10:15, referring to "another Quran." This particular occurrence, therefore, must be excluded. Thus, the frequency of occurrence of "this Quran" in the Quran is 57, or 19x3.
            </p>

            <h5 className='my-3 text-black text-center mt-10 italic'>Table 21: Suras and Verses Where "Quran" Occurs</h5>
            <div className='table-con my-5 max-w-screen-lg mx-auto grid grid-cols-1 md:grid-cols-2 gap-5'>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sura</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Verse</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>185</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>82</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>101</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>204</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>111</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>10</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>37</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>61</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>12</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>15</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>87</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>91</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>16</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>98</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>17</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>41</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>45</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>46</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>60</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>78</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>82</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>88</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>89</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>106</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>18</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>54</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>113</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>114</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>25</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>30</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>32</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>27</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>76</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>92</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>28</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>85</td>
                  </tr>
                </tbody>
              </table>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sura</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Verse</th>
                  </tr>
                </thead>
                <tbody>
                   <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>30</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>58</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>34</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>31</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>36</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>69</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>38</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>39</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>27</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>28</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>41</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>26</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>42</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>43</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>31</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>46</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>29</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>47</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>24</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>50</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>45</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>54</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>17</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>22</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>32</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>40</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>55</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>56</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>77</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>59</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>21</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>72</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>73</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>75</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>17</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>18</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>76</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>23</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>84</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>21</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>85</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>21</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1356</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3052</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-center' colSpan={2}>1356 + 3052 = 4408 (19x232)</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p>
              Two other grammatical forms of the word "Quran" occur in 12 verses. These include the word "Quranun" and the word "Quranahu." One of these occurrences, in 13:31 refers to "another Quran" that cause the mountains to crumble. Another occurrence, in 41:44, refers to "a non-Arabic Quran." These two occurrences, therefore, are excluded. Table 21 shows a list of the suras and verses where the word "Quran," in all its grammatical forms, occurs.
            </p>

            <h5 className='my-3 text-black mt-10'>A STRONG FOUNDATION</h5>
            <p>
              The Quran's first verse, "In the Name of God, Most Gracious, Most Merciful," known as Basmalah, consists of 19 Arabic letters. Its constituent words occur in the Quran consistently in multiples of 19.
            </p>

            <div className='table-con my-5 max-w-screen-md mx-auto'>
              <table className='w-full border-0'>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The first word</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>"Ism" (Name)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>	occurs </td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19 times.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The second word	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>"Allah"(God)	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>occurs	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2698 times (19x142).</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The third word</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>"Al-Rahman"</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>(Most Gracious)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>57 times, 19x3.</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>The fourth word</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>"Al-Raheem"</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>(Most Merciful)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>114 times, 19x6.</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p>
              Professor Cesar Majul looked at the gematrical value of more than 400 attributes of God, and found only four names whose gematrical vaues are multiples of 19:
            </p>


            <div className='table-con my-5 max-w-screen-md mx-auto'>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Divine Name</th>
                    <th style={{ width: '30%' }} className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Gematrical Va</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1. "Waahed" (One)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2. "Zul Fadl Al-'Azim" (Possessor of Infinite Grace)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2698</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3. "Majid" (Glorious)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>57</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4. "Jaami'" (Summoner)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>114</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p>
              As noted above, the only Divine Names whose gematrical values are divisible by 19 correspond exactly to the frequencies of occurrence of the Basmalah's four words. The figure below illustrates this remarkable phenomenon:
            </p>
            <div className='my-5 max-w-screen-lg mx-auto border border-[1px] border-primary'>
              <img src={namesOfGodImg} alt='Names Of God'/>
              <p className='my-3 p-3 border-t-[1px] border-primary font-medium italic'>
                The four words of Basmalah are shown on the left side, and the only four divine names whose gematrical values are divisible by 19 are on the right side. The numbers in the middle are the frequencies of occurrence of the words of Basmalah, and, at the same time, the gematrical values of the four divine names.
              </p>
            </div>

            <h5 className='my-3 text-black mt-10'>The Five Pillars of Islam</h5>
            <p>
              Although the Quran provides numerous important commandments governing all aspects of our lives (see for example 17:22-38), five basic "pillars" have been traditionally emphasized. They are:
            </p>

            <div className='my-5 max-w-screen-xl mx-auto flex flex-col lg:flex-row'>
              <div className='basis-2/3'>
                <ul className='ms-7 list-decimal'>
                  <li>Shahaadah: Bearing witness that there is no other god besides God.</li>
                  <li>Salat: Observing five daily Contact Prayers.</li>
                  <li>Seyaam: Fasting during the ninth month of the Islamic calendar (Ramadan).</li>
                  <li>Zakat: Giving away 2.5% of one's net income as a charity to specified people.</li>
                  <li>Hajj: Pilgrimage to Mecca once in a lifetime for those who can afford it.</li>
                </ul>
                <p>
                  Like everything else in the Quran, these are mathematically structured.
                </p>
                <h5 className='my-3 text-black mt-10'>1. One God (Shahaadah):</h5>
                <p>
                  As mentioned earlier, the word "ONE" that refers to God occurs in the Quran 19 times. The reference to God "ALONE" occurs 5 times, and the sum of the sura and verse numbers where we find these five occurrences is 361, 19x19.
                  <br/><br/>
                  The "First Pillar of Islam" is stated in 3:18 as "<b>LAA ELAAHA ELLA HOO</b>" (There is no other god besides Him). This most important expression occurs in 19 suras. The first occurrence is in 2:163, and the last occurrence is in 73:9. Table 22 shows that the total of sura numbers, plus the number of verses between the first and last occurrences, plus the sum of these verse numbers is 316502, or 19x 16658.
                  <br/><br/>
                  Also, by adding the numbers of the 19 suras where <b>LAA ELAAHA ELLA HOO</b> occurs, plus the verse numbers where this crucial expression is found, plus the total number of occurrences (29), the Grand Total comes to 2128, or 19x112. The details are shown in Table 23.
                </p>
              </div>
              <div className='basis-1/3'>
                <h5 className='my-3 text-black text-center mt-10 italic'>Table 22: All Suras and Verses from First Occurrence of LAA ELAAHA ELLA HOO to the Last Occurrence.</h5>
                <div className='table-con my-5 max-w-screen-lg mx-auto  '>
                  <table className='w-full border-0'>
                    <thead className='bg-primary'>
                      <tr>
                        <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                        <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>No.of Verses</th>
                        <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sum of Verse #s</th>
                        <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>123</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>27675</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>27800</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>200</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20100</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20303</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>127</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8128</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8264</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>72</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>28</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>406</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>506</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>73</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>45</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>127</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2700</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5312</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>308490</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>316502</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>(19x16658)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <h5 className='my-3 text-black text-center mt-10 italic'>Table 23: List of All Occurrences of the Crucial Phrase: "LAA ELAAHA ELLA HOO" (There is no other god besides Him).</h5>
            <div className='table-con my-5 max-w-screen-md mx-auto  '>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>No</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Verses with Shahadah</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Frequency of Shahadah</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>163,255</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2,6,18 (twice)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>87</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>102,106</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>158</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>158</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>31</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>11</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>14</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>13</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>30</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8,98</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>10.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>23	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>116</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>11.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>27	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>26</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>12.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>28</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>70,88</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>13.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>35</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>14.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>39</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>15.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>40</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3,62,65</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>16.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>44</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>17.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>59</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>22,23</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>18.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>64</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>13</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19.</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>73</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>507</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1592</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>29</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-center' colSpan={4}>507 + 1592 + 29 = 2128 = 19x112</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h5 className='my-3 text-black mt-10'>2. The Contact Prayers "Salat":</h5>
            <p>
              The word "Salat " occurs in the Quran 67 times, and when we add the numbers of suras and verses of these 67 occurrences, the total comes to 4674, or 19x246 (see INDEX OF THE QURAN).
            </p>

            <h5 className='my-3 text-black mt-10'>3. Fasting (Seyaam):</h5>
            <p>
              The commandment to fast is mentioned in 2:183, 184, 185, 187, 196; 4:92; 5:89, 95; 33:35, 35; & 58:4. The total of these numbers is 1387, or 19x73. It is noteworthy that 33:35 mentions fasting twice, one for the believing men, and the other for the believing women.
            </p>

            <h5 className='my-3 text-black mt-10'>4. The Obligatory Charity (Zakat): &</h5>

            <h5 className='my-3 text-black mt-10'>5. Hajj Pilgrimage to Mecca</h5>
            <p>
              While the first three "Pillars of Islam" are obligatory upon all Muslim men and women, the Zakat and Hajj are decreed only for those who can afford them. This explains the interesting mathematical phenomenon associated with Zakat and Hajj.
              <br/><br/>
              The Zakat charity is mentioned in 2:43, 83, 110, 177, 277; 4:77, 162; 5:12, 55, 7:156; 9:5, 11, 18, 71; 18:81; 19:13, 31, 55; 21:73; 22:41, 78; 23:4; 24:37, 56; 27:3; 30:39; 31:4; 33:33; 41:7; 58:13; 73:20; and 98:5. These numbers add up to 2395. This total does not quite make it as a multiple of 19; it is up by 1.
              <br/><br/>
              The Hajj Pilgrimage occurs in 2:189, 196, 197; 9:3; and 22:27. These numbers add up to 645, and this total does not quite make it as a multiple of 19; it is down by 1.
              <br/><br/>
              Thus, Zakat and Hajj, together, give a total of 2395+645 = 3040 = 19x160.
            </p>

            <h5 className='my-3 text-black mt-10'>THE QURAN'S Mathematical Structure</h5>
            <p>
              The Quran's suras, verses, words, and letters are not only mathematically composed, but also arranged into a superhuman structure that is purely mathematical, i.e., the literary content has nothing to do with such an arrangement.
              <br/><br/>
              Since the physical construction of the Quran is purely mathematical, it would be expected that the numbers mentioned in the Quran must conform with the Quran's 19-based code.
              <br/><br/>
              A total of 30 unique numbers are mentioned throughout the Quran, and the sum of all these numbers is 162146, a multiple of 19 (162146 = 19x8534). Table 24 lists all the numbers mentioned in the Quran, without the repetitions.
              <br/><br/>
              The numbers which are mentioned only once in the Quran are: 11, 19, 20, 50, 60, 80, 99, 300, 2000, 3000, 5000, 50000, and 100000.
              <br/><br/>
              All the numbers mentioned in the Quran, with repetitions, occur 285 times, and this number is a multiple of 19; 285 = 19x15.
            </p>

            <h5 className='my-3 text-black mt-10'>The Numbers of Suras and Verses</h5>
            <p>
              The numbering system of the Quran's suras and verses has been perfectly preserved. Only a few unauthorized and easily detectable printings deviate from the standard system that is divinely guarded.
              <br/><br/>
              When we add the numbers of all suras, plus the number of verses in every sura, plus the sum of verse numbers, the Grand total for the whole Quran comes to 346199, 19x19x959. Table 25 is an abbreviated presentation of these data. Thus, the slightest alteration of a single sura or verse would have destroyed this system. As shown in Table 16, if we consider only the 29 initialed suras, these same data produce a Grand Total which is also a multiple of 19. It follows that the data for the un-initialed suras are also divisible by 19.
            </p>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
              <div>
                <h5 className='my-3 text-black text-center mt-10 italic'>Table 24: All the Quranic Numbers</h5>
                <div className='table-con my-5 max-w-screen-lg mx-auto'>
                  <table className='w-full border-0'>
                    <thead className='bg-primary'>
                      <tr>
                        <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Number</th>
                        <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Location Example</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2:163</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4:11</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4:171</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9:2</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>18:22</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>25:59</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>41:12</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>69:17</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>27:48</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>10</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2:196</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>11</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>12:4</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>12</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9:36</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>19</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>74:30</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8:65</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>30</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7:142</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>40</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7:142</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>50</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>29:14</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>60</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>58:4</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>70</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9:80</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>80</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>24:4</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>99</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>38:23</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>100</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2:259</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>200</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8:65</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>300</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>18:25</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1000</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2:96</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2000</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8:66</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3000</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3:124</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5000</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3:125</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>50000</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>70:4</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>100000</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>37:147</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='p-4 text-black text-[12px] md:text-[14px] text-start' colSpan={2}>162146 (19 x 8534)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <h5 className='my-3 text-black text-center mt-10 md:mt-3 italic'>Table 25: Mathematical Coding of the Sura and Verse Numbers.</h5>
                <div className='table-con my-5 max-w-screen-lg mx-auto'>
                  <table className='w-full border-0'>
                    <thead className='bg-primary'>
                      <tr>
                        <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                        <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>No.of Verses</th>
                        <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sum of Verse #s</th>
                        <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>28</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>36</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>286</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>41041</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>41329</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>127</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8128</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8264</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>113</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>15</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>133</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>114</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>21</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>141</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6555</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6234</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>333410</td>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>346199</td>
                      </tr>
                      <tr className='border-[1px]'>
                        <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-end' colSpan={4}>(19x19x959)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <p>
              Table 26 is an abbreviated presentation of the same data related to the 85 un-initialed suras.
              <br/><br/>
              Now let us look at another set of miracles involving the sura and verse numbers.
            </p>

            <h5 className='my-3 text-black text-center mt-10 italic'>Table 26: Mathematical Coding of the 85 Un-initialed Suras</h5>
            <div className='table-con my-5 max-w-screen-md mx-auto'>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sura No.</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>No.of Verses</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sum of Verse #s</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>28</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>36</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>4</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>176</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>15576</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>15756</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>9</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>127</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8128</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8264</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>113</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>15</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>133</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>114</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>21</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>141</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>5733</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3491</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>146842</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>156066</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-end' colSpan={4}>(19x8214)</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p>
              Write down the sura number, followed by the number of verses in that sura, then the number of every verse, and finally, the sum of verse numbers. So, for Sura 1 you would write 1 for the sura, then 7 for the number of verses, 1234567 for each verse number, and finally 28 for the sum of verse numbers, i.e. 1 7 1234567 28. The number for Sura 2 will look like this: 2 286 123456.... 286 41041. Do the same for all the 114 suras, and than add these numbers. The total consists of 759 digits, and is a multiple of 19.
            </p>
            <h5 className='my-3 text-black text-center mt-10 italic'>Table 27: Sura, Total Number of Verses, Verse numbers and Sum of Verse numbers for every sura.</h5>
            <div className='table-con my-5 max-w-screen-md mx-auto'>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sura</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sura, # of Verses, Verse #'s & Sum of Verse #'s</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>17123456728</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>228612345........28641041</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>114</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>114612345621</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-center' colSpan={2}>Total is 759 digits & a multiple of 19</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p>
              Now write down the total number of verses in a sura, followed by the sum of verse numbers, and keep all numbers justified to the left. For example, the number of verses in Sura 1 is 7, and the sum of verse numbers is 28. Thus, the combined number for Sura 1 will be 7 28, for Sura 2 it will be 286 41041, for Sura 3 it will be 200 20100; and so on to Sura 114 for which the combined number is 6 21. Remember that these numbers are written all the way to the left, as shown in Table 28. Then add them in the usual manner, from right to left. The total of all these left justified numbers is 4,859,309,774, or 19 x 255753146.
            </p>

            <h5 className='my-3 text-black text-center mt-10 italic'>Table 28: Number of verses for every sura & sum of verse numbers, justified to the left.</h5>
            <div className='table-con my-5 max-w-screen-md mx-auto'>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sura</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Total Verses & Sum of Verses</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>728</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>28641041</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>3</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>20020100</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>114</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>621</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-center' colSpan={2}>4859309774 = 19 x 255753146</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p>
              Finally, do the same thing as above (continuing to keep all numbers left justified), except write down the number of every verse, instead of the total number of verses. For example, the number for Sura 1 consists of its seven verse numbers (1234567) combined with the sum of those numbers (28). Thus, the combined number for Sura 1 will be 1234567 28. The combined number for Sura 114 will be 123456 21. Table 29 demonstrates this process. The total of all these left justified numbers consists of 757 digits, and is still a multiple of 19.
            </p>

            <h5 className='my-3 text-black text-center mt-10 italic'>Table 29: Verse numbers and Sum of Verse numbers, justified to the left.</h5>
            <div className='table-con my-5 max-w-screen-md mx-auto'>
              <table className='w-full border-0'>
                <thead className='bg-primary'>
                  <tr>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Sura</th>
                    <th className='border-0 p-4 text-white text-start text-[12px] md:text-[14px] uppercase'>Verse #'s & Sum of Verses</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>123456728</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>2</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1234...28641041</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>-</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>114</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>12345621</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-center' colSpan={2}>Total is 757 digits & a multiple of 19</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h5 className='my-3 text-black mt-10'>Superhuman Numerical Combinations</h5>
            <p>
              Let us write down the number of each verse in the Quran, preceded for each sura by the number of verses in that sura. Thus, Sura 1, which consists of seven verses, will be represented by the number 7 1234567. What we are doing here is forming long numbers by writing the numbers of verses next to each other. To find the number representing Sura 2, you write down the number of verses in this sura, 286, followed by the number of every verse, written next to each other. Thus, the number representing Sura 2 will look like this: 286 12345.....284285286. The two numbers representing the first two suras are:
            </p>
            <p className='text-center'>7 1 2 3 4 5 6 7 & 286 1 2 3 4 5.....284 285 286.</p>
            <p>Putting these two numbers together to form one number representing the first two suras, we get this number:</p>
            <p className='text-center'>7 1 2 3 4 5 6 7 286 1 2 3 4 5.....284 285 286.</p>
            <p>This process is continued until every verse in the Quran is written down, thus forming one very long number encompassing the number of every verse in the Quran. The number representing the whole Quran is a multiple of 19 & consists of 12692 digits, which is also a multiple of 19.</p>
            
            <div className='rounded-[10px] border-[1px] border-primary my-5 max-w-screen-md mx-auto'>
              <p className='p-2 text-center border-b-[1px] border-primary'>7 1234567 286 12345...286 ...5 12345 6 123456</p>
              <p className='p-2'>FIRST No: This very long number consists of 12692 digits (19x668) and includes every verse in the Quran. The number of verses in each sura precedes its verses. A special computer program that divides very long numbers has shown that this long number is a multiple of 19.</p>
            </div>

            <p>
              Instead of putting the total number of verses in every sura ahead of the sura, let us put it at the end of every sura. Thus, the number representing Sura 1 will look like this: 1234567 7, instead of 7 1234567. The number representing Sura 2 will look like this: 12345.....284 285 286 286 instead of 286 12345.....284285286. The numbers representing the first two suras will look like this:
            </p>
            <p className='text-center'>1 2 3 4 5 6 7 7 & 1 2 3 4 5.....284 285 286 286.</p>
            <p>Putting these two numbers together to form a longer number representing the first two suras, we get a number that looks like this:</p>
            <p className='text-center'>1 2 3 4 5 6 7 7 1 2 3 4 5.....284 285 286 286.</p>
            <p>
              Since we are putting the total number of verses per sura at the end of each sura, we must put the total number of numbered verses (6234) at the end of the Quran. The last numbers, therefore, represent the last sura (123456 6), followed by the total number of numbered verses in the Quran (6234):
            </p>
            <p className='text-center'>1 2 3 4 5 6 6 &amp; 6234 &gt; &gt; &gt; 1 2 3 4 5 6 6 6234.</p>

            <p>Putting together all the verses of all the suras, produces a long number that consists of 12696 digits, and is a multiple of 19.</p>
            <div className='rounded-[10px] border-[1px] border-primary my-5 max-w-screen-md mx-auto'>
              <p className='p-2 text-center border-b-[1px] border-primary'>1234567 7 12345...286 286 12345 5...123456 6 6234</p>
              <p className='p-2'>SECOND No: The number of every verse in every sura is followed by the number of verses per sura. The last 11 digits shown here are the 6 verses of the last sura, followed by its number of verses (6), followed by the total number of numbered verses in the Quran (6234). The complete, very long number, is a multiple of 19.</p>
            </div>

            <h5 className='my-3 text-black mt-10'>Now let us include the number of every sura.</h5>
            <p>
              Write down the number of every verse in every sura, followed by the number of the sura, followed by the number of verses in the sura. Thus, the number representing Sura 1 looks like this: 1 2 3 4 5 6 7 1 7. The number representing Sura 2 looks like this: 1 2 3 4 5 .....284 285 286 2 286. The number representing the last sura (No. 114) looks like this: 1 2 3 4 5 6 114 6. Again, the total number of numbered verses (6234) is added at the end. This number, representing the whole Quran, is a multiple of 19; it looks like this:
            </p>
            <div className='rounded-[10px] border-[1px] border-primary my-5 max-w-screen-md mx-auto'>
              <p className='p-2 text-center border-b-[1px] border-primary'>1234567 1 7 12345...286 2 286 ...123456 114 6 6234</p>
              <p className='p-2'>THIRD No: The number of every verse, followed by the sura number, then the number of verses in the sura. The total number of numbered verses is added at the end. The long number (12930 digits) is a multiple of 19.</p>
            </div>

            <p>
              Instead of putting the total number of verses in every sura after the sura, let us now put it ahead of the sura. Thus, the number representing Sura 1 looks like this: 7 1234567 1, instead of 1234567 1 7, and the number representing Sura 2 looks like this: 286 12345.....284 285 286 2, instead of 12345.....284 285 286 2 286. This very long number representing the whole Quran is a multiple of 19.
            </p>
            <div className='rounded-[10px] border-[1px] border-primary my-5 max-w-screen-md mx-auto'>
              <p className='p-2 text-center border-b-[1px] border-primary'>7 1234567 1 286 12345...286 2...6 123456 114 6234</p>
              <p className='p-2'>FOURTH No: The total number of verses in each sura is followed by the number of every verse, then the sura number. The last 14 digits shown above are the number of verses in the last sura (6), followed by the numbers of the six verses (123456), followed by the number of the sura (114), then the total number of numbered verses in the Quran.</p>
            </div>
            <p>
              The very long number (consisting of 12930 digits) is a multiple of 19.
              <br/><br/>
              Now, let us write down the number of every verse in every sura, followed by the sum of verse numbers for every sura. Sura 1 consists of 7 verses, and the sum of verse numbers is 1+2+3+4+5+6+7 = 28. Thus, the number representing Sura 1 looks like this: 1234567 28.
              <br/><br/>
              The sum of verse numbers for Sura 2 is 41041 (1+2+3+ ... + 286). Thus, the number representing Sura 2 looks like this: 12345...284 285 286 41041.
              <br/><br/>
              The number representing the last sura, which consists of 6 verses, looks like this: 123456 21, since 1+2+3+4+5+6 = 21.
              <br/><br/>
              The complete number, representing the whole Quran, consists of 12836 digits and is a multiple of 19. It looks like this:
            </p>
            <div className='rounded-[10px] border-[1px] border-primary my-5 max-w-screen-md mx-auto'>
              <p className='p-2 text-center border-b-[1px] border-primary'>1234567 28 12345...284285286 41041...123456 21</p>
              <p className='p-2'>FIFTH No: The number of every verse in every sura is followed by the sum of verse numbers. The long number consists of 12836 digits, and is a multiple of 19.</p>
            </div>
            <p>
              Remarkably, if we take the "Fifth No." shown above and reverse the order of verse numbers and sum of verse numbers, i.e., move the sum of verse numbers, and put it ahead of the sura, the resulting long number is still a multiple of 19.
            </p>
            <div className='rounded-[10px] border-[1px] border-primary my-5 max-w-screen-md mx-auto'>
              <p className='p-2 text-center border-b-[1px] border-primary'>28 1234567 41041 12345....285286.....21 123456</p>
              <p className='p-2'>SIXTH No: Placing the sum of verse numbers ahead of each sura, instead of after it, produces a long number (12836 digits) that is also a multiple of 19.</p>
            </div>
            <p>
              Even writing the suras backward, i.e., reversing the order of suras by starting with the last sura and ending with the first sura, and placing the sum of verse numbers after the verses of each sura, the product is still a multiple of 19
            </p>

            <div className='rounded-[10px] border-[1px] border-primary my-5 max-w-screen-md mx-auto'>
              <p className='p-2 text-center border-b-[1px] border-primary'>123456 21 12345 15..12345..286 41041 1234567 28</p>
              <p className='p-2'>SEVENTH No: Reversing the order of suras - starting from the last sura and ending with the first sura - and writing down the number of every verse, with the sum of verse numbers for every sura after its verses, the product is a long number consisting of 12836 digits. This long number is a multiple of 19.</p>
            </div>
            <p>
              Write the sum of verse numbers for the whole Quran (333410), followed by the total number of numbered verses in the Quran (6234), then the number of suras (114). Every sura is then represented by its number followed by its number of verses. The numbers representing Suras 1 and 2 are 1 7 and 2 286. The complete number, covering all suras of the Quran, consists of 474 digits, and is a multiple of 19 - it looks like this:
            </p>

            <div className='rounded-[10px] border-[1px] border-primary my-5 max-w-screen-md mx-auto'>
              <p className='p-2 text-center border-b-[1px] border-primary'>333410 6234 114 1 7 2 286 3 200..113 5 114 6</p>
              <p className='p-2'>EIGHTH No: The Grand Sum of verse numbers (333410) is followed by the total number of numbered verses (6234), the number of suras (114), then the sura numbers and numbers of verses of every sura.</p>
            </div>
            <p>
              Now let us reverse the order of sura number and its number of verses as presented in the "Eighth No." Thus, the numbers representing the first two suras look like this: 7 1 & 286 2, instead of 1 7 & 2 286. The complete number also consists of 474 digits and is still a multiple of 19. It looks like this:
            </p>
            <div className='rounded-[10px] border-[1px] border-primary my-5 max-w-screen-md mx-auto'>
              <p className='p-2 text-center border-b-[1px] border-primary'>333410 6234 114 7 1 286 2 200 3...5 113 6 114</p>
              <p className='p-2'>NINTH No: Reversing the sequence of sura number and number of verses still gives us a long number that is a multiple of 19</p>
            </div>

            <p>
              If we write down the sum of verse numbers for Sura 1 (28), followed by the sum of verse numbers for Sura 2 (41041), and so on to the end of the Quran, and placing the Grand Sum of verse numbers (333410) at the end, the resulting long number (Tenth No.) consists of 377 digits, and is a multiple of 19.
            </p>
            <div className='rounded-[10px] border-[1px] border-primary my-5 max-w-screen-md mx-auto'>
              <p className='p-2 text-center border-b-[1px] border-primary'>28 41041 20100 ..... 15 21 333410</p>
              <p className='p-2'>
                TENTH No: The sums of verse numbers for every sura in the Quran, are written next to each other, followed at the end by the Grand Sum of verse numbers (333410).
                <br/>This long number (377 digits) is a multiple of 19.
              </p>
            </div>

            <p>
              If we write down the number of suras in the Quran (114), followed by the total number of numbered verses (6234), followed by the number of every sura and its sum of verse numbers, the final long number (612 digits) is a multiple of 19.
            </p>
            <div className='rounded-[10px] border-[1px] border-primary my-5 max-w-screen-md mx-auto'>
              <p className='p-2 text-center border-b-[1px] border-primary'>114 6234 1 28 2 41041 3 20100...113 15 114 21</p>
              <p className='p-2'>
              ELEVENTH No: The number of suras, followed by the total number of numbered verses, then the number of every sura and its sum of verse numbers, produce this long number (612 digits) that is a multiple of 19.
              </p>
            </div>
            <p>
              Lest anyone may think that any Quranic parameter is left un-guarded with this awesome mathematical code, let us look at more parameters.
              <br/>
              If we write down the number of suras (114), followed by the total number of numbered verses, followed by the Grand Sum of verse numbers in the whole Quran (333410), followed by the numbers of every sura and its verses, we end up with a very long number (12712 digits) that is a multiple of 19.
            </p>
            <div className='rounded-[10px] border-[1px] border-primary my-5 max-w-screen-md mx-auto'>
              <p className='p-2 text-center border-b-[1px] border-primary'>114 6234 333410 1 1 2 3 4 5 6 7...114 1 2 3 4 5 6</p>
              <p className='p-2'>
                TWELFTH NUMBER
              </p>
            </div>

            <p>
              If we write down the numbers of verses in every sura next to each other, we end up with a 235-digit number that is a multiple of 19. To do this, write down the total number of numbered verses in the Quran (6234), followed by the number of verses in every sura, then close with the total number of numbered verses in the Quran. The final long number looks like this:
            </p>

            <div className='table-con my-5 max-w-screen-md mx-auto'>
              <table className='w-full border-0'>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6234	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>7   286   200   176	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>..... 127	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>.... 5   4   5   6	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>6234</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>(total verses)	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>(First 4 suras)	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>(Sura 9)	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>(Last 4 suras)	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>(total verses)</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='p-4 text-black text-[12px] md:text-[14px] text-start' colSpan={5}>THIRTEENTH NUMBER</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p>
              If we write down the number of numbered verses in the Quran (6234), followed by the number of suras (114), followed by the number of every verse in every sura, then close with the number of numbered verses in the Quran (6234) and the number of suras (114), the final number consists of 12479 digits, and is a multiple of 19.
            </p>
            <div className='rounded-[10px] border-[1px] border-primary my-5 max-w-screen-md mx-auto'>
              <p className='p-2 text-center border-b-[1px] border-primary'>6234 114 1234567 12345...286...123456 6234 114</p>
              <p className='p-2'>
                FOURTEENTH NUMBER
              </p>
            </div>

            <p>
              Another long number that consists of 12774 digits is formed by writing down the number of every verse in every sura, followed by the number of every sura added to its number of verses. Sura 1 consists of 7 verses, and the total 1+7 is 8. Therefore, the number representing Sura 1 looks like this: 1234567 8. Since Sura 2 consists of 286 verses, the number representing Sura 2 looks like this: 12345...286 288. This is done for every sura in the Quran. The final combined number consists of 12774 digits, and is a multiple of 19.
            </p>

            <div className='table-con table-responsive my-5 max-w-screen-md mx-auto'>
              <table className='w-full border-0'>
                <tbody>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>1234567</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>8</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>12345</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>......	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>286</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>288</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>...........	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>123456	</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>120</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>(1+7)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>(2+286)</td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'></td>
                    <td className='px-4 py-2 text-black text-[12px] md:text-[14px] text-start'>(114+6)</td>
                  </tr>
                  <tr className='border-[1px]'>
                    <td className='p-4 text-black text-[12px] md:text-[14px] text-start' colSpan={9}>FIFTEENTH NUMBER</td>
                  </tr>
                  
                </tbody>
              </table>
            </div>

            <p>More specialized features are in Appendices 2, 9, 19, 24, 25, 26, 29, and 37.</p>

            <h5 className='my-3 text-black mt-10'>A Witness From the Children of Israel [46:10]</h5>
            <div className='p-4 rounded-[10px] border-[1px] border-primary my-5 max-w-screen-md mx-auto'>
              <p>
                Proclaim: "What if it is from God, and you disbelieved in it? A witness from the Children of Israel has borne witness to a similar phenomenon, and he has believed, while you have turned too arrogant to believe. God does not guide the wicked." [ 46:10 ]
              </p>
            </div>

            <p>
              The following quotation is taken from STUDIES IN JEWISH MYSTICISM, (Association for Jewish Studies, Cambridge, Mass., Joseph Dan & Frank Talmage, eds., Page 88, 1982). The quotation refers to the work of Rabbi Judah the Pious (12th Century AD):
              <br/><br/>
              The people [Jews] in France made it a custom to add [in the morning prayer] the words: " 'Ashrei temimei derekh [blessed are those who walk the righteous way]," and our Rabbi, the Pious, of blessed memory, wrote that they were completely and utterly wrong. It is all gross falsehood, because there are only nineteen times that the Holy Name is mentioned [in that portion of the morning prayer]... and similarly you find the word 'Elohim nineteen times in the pericope of Ve-'elleh shemot. . . . Similarly, you find that Israel were called "sons" nineteen times, and there are many other examples. All these sets of nineteen are intricately intertwined, and they contain many secrets and esoteric meanings, which are contained in more than eight large volumes. . . Furthermore, in this section there are 152 (19x8) words.
            </p>

            <h5 className='my-3 text-black mt-10'>Acknowledgments</h5>
            <div className='p-4 rounded-[10px] border-[1px] border-primary my-5 max-w-screen-md mx-auto'>
              <p>
                All praise and thanks are due to God who has willed that His miracle of the Quran shall be revealed at this time. He has distinguished the following individuals and blessed them by revealing through them many portions of this momentous discovery: Abdullah Arik, Mohamoud Ali Abib, Lisa Spray, Edip Yuksel, Ihsan Ramadan, Feroz Karmally, Ismail Barakat, Gatut Adisoma, Ahmed Yusuf, Cesar A. Majul, Muhtesem Erisen, and Emily Kay Sterrett.
              </p>
            </div>

        </div>
        <div className='flex justify-end my-6 content-container'>
            <LinkButton btnLink={'/chapters/appendix-2'} btnText={"God's Messenger of the Covenant"}/>
        </div>
    </div>
  )
}

export default AppendixOne