import React from 'react'

const TogglerIcon = () => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.22728 18C0.879549 18 0.588071 17.8823 0.352842 17.647C0.117614 17.4117 0 17.1201 0 16.7722C0 16.4243 0.117614 16.1329 0.352842 15.8979C0.588071 15.663 0.879549 15.5455 1.22728 15.5455H18.7553C19.103 15.5455 19.3945 15.6632 19.6297 15.8985C19.8649 16.1338 19.9825 16.4254 19.9825 16.7733C19.9825 17.1212 19.8649 17.4126 19.6297 17.6475C19.3945 17.8825 19.103 18 18.7553 18H1.22728ZM1.22728 10.2272C0.879549 10.2272 0.588071 10.1096 0.352842 9.87427C0.117614 9.63894 0 9.34733 0 8.99947C0 8.65158 0.117614 8.36015 0.352842 8.1252C0.588071 7.89024 0.879549 7.77276 1.22728 7.77276H13.9721C14.3198 7.77276 14.6112 7.89042 14.8465 8.12573C15.0817 8.36107 15.1993 8.65267 15.1993 9.00053C15.1993 9.34843 15.0817 9.63985 14.8465 9.87481C14.6112 10.1098 14.3198 10.2272 13.9721 10.2272H1.22728ZM1.22728 2.45452C0.879549 2.45452 0.588071 2.33685 0.352842 2.10151C0.117614 1.8662 0 1.5746 0 1.22671C0 0.878841 0.117614 0.587431 0.352842 0.352475C0.588071 0.117493 0.879549 0 1.22728 0H18.7553C19.103 0 19.3945 0.11767 19.6297 0.353007C19.8649 0.588345 19.9825 0.879945 19.9825 1.22781C19.9825 1.5757 19.8649 1.86713 19.6297 2.10208C19.3945 2.33704 19.103 2.45452 18.7553 2.45452H1.22728Z" fill="white"/>
    </svg>
  )
}

export default TogglerIcon